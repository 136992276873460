import React, { useState, useEffect } from "react";
import Select from "react-select";
import "../../styles.css";
import VisualDataset from "./VisualDataset";
import { useDispatch, useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import {
  changeDistrictName,
  changeLevel3Name,
  changeLevel4Name,
  changeStateName,
  changeLevel1Name,
  changeLevel2Name,
} from "../../redux/action";
import { removeLayerByName } from "../../lib/gis";
import { useMap } from "../../hooks/useMap";

const AdministrativeBoundary = (props) => {
  const [exploreDataset, setExploreDataset] = useState(false);
  const [states, setStates] = useState([]);
  const stateName = useSelector((state) => state.stateName);
  const [districts, setDistricts] = useState([]);
  const districtName = useSelector((state) => state.districtName);
  const [level4, setLevel4] = useState([]);
  const level4Data = useSelector((state) => state.level4Data);
  const [level3, setLevel3] = useState([]);
  const level3Data = useSelector((state) => state.level3Data);
  const availableCountries = [
    "Tanzania",
    "Zambia",
    "Sudan",
    "Bangladesh",
    "Kenya",
  ];
  const [stateDisabled, setStateDisabled] = useState(true);
  const [districtDisabled, setDistrictDisabled] = useState(true);
  const [level3Disabled, setLevel3Disabled] = useState(true);
  const [level4Disabled, setLevel4Disabled] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState("Location");
  const { map } = useMap();

  const dispatch = useDispatch();
  useEffect(() => {
    return () => handleResetFields();
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (props.countryName) {
        // Enable state selection
        setStateDisabled(false);
        setDistrictDisabled(true);
        setLevel3Disabled(true);
        setLevel4Disabled(true);
      } else if (stateName) {
        setDistrictDisabled(false);
        setLevel3Disabled(true);
        setLevel4Disabled(true);
      } else if (districtName) {
        setLevel3Disabled(false);
        setLevel4Disabled(true);
      } else if (level3Data) {
        setLevel4Disabled(false);
      } else {
        setStateDisabled(true);
        setDistrictDisabled(true);
        setLevel3Disabled(true);
        setLevel4Disabled(true);
      }
    }

    fetchData();
  }, [props.countryName]);

  useEffect(() => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      let levelName = "";
      if (props.countryName === "Tanzania") {
        levelName = "state";
      } else if (props.countryName === "Zambia") {
        levelName = "province";
      } else if (props.countryName === "Bangladesh") {
        levelName = "division";
      } else if (props.countryName === "Sudan") {
        levelName = "state";
      } else if (props.countryName === "Kenya") {
        levelName = "county";
      }

      if (props.countryName != null) {
        fetch(
          `/api/explore/selectLocation?country=${props.countryName}&level=${levelName}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            setStates(data);
            setStateDisabled(false);

            if (
              stateName !== null &&
              (props.countryName === "Tanzania" ||
                props.countryName === "Zambia" ||
                props.countryName === "Bangladesh" ||
                props.countryName === "Kenya" ||
                props.countryName === "Sudan")
            ) {
              let level2Name = " ";
              if (
                props.countryName === "Tanzania" ||
                props.countryName === "Zambia" ||
                props.countryName === "Sudan"
              ) {
                level2Name = "district";
              } else if (props.countryName === "Bangladesh") {
                level2Name = "zila";
              } else if (props.countryName === "Kenya") {
                level2Name = "constituency";
              }
              fetch(
                `/api/explore/selectLocation?country=${props.countryName}&${levelName}=${stateName}&level=${level2Name}`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: jwtToken,
                  },
                }
              )
                .then((response) => response.json())
                .then((data) => {
                  setDistricts(data);
                  setDistrictDisabled(false);

                  if (
                    districtName !== null &&
                    (props.countryName === "Tanzania" ||
                      props.countryName === "Zambia" ||
                      props.countryName === "Bangladesh" ||
                      props.countryName === "Kenya" ||
                      props.countryName === "Sudan")
                  ) {
                    let level3Name = "";
                    if (
                      props.countryName === "Tanzania" ||
                      props.countryName === "Kenya" ||
                      props.countryName === "Sudan"
                    ) {
                      level3Name = "ward";
                    } else if (props.countryName === "Zambia") {
                      level3Name = "block";
                    } else if (props.countryName === "Bangladesh") {
                      level3Name = "upazila";
                    }

                    fetch(
                      `/api/explore/selectLocation?country=${props.countryName}&${levelName}=${stateName}&${level2Name}=${districtName}&level=${level3Name}`,
                      {
                        method: "GET",
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: jwtToken,
                        },
                      }
                    )
                      .then((response) => response.json())
                      .then((data) => {
                        setLevel3(data);
                        setLevel3Disabled(false);

                        if (
                          level3Data != null &&
                          (props.countryName === "Zambia" ||
                            props.countryName === "Bangladesh")
                        ) {
                          let level4Name = "";
                          if (props.countryName === "Zambia") {
                            level4Name = "camp";
                          } else if (props.countryName === "Bangladesh") {
                            level4Name = "union";
                          }
                          fetch(
                            `/api/explore/selectLocation?country=${props.countryName}&${levelName}=${stateName}&${level2Name}=${districtName}&${level3Name}=${level3Data}&level=${level4Name}`,
                            {
                              method: "GET",
                              headers: {
                                "Content-Type": "application/json",
                                Authorization: jwtToken,
                              },
                            }
                          )
                            .then((response) => response.json())
                            .then((data) => {
                              setLevel4(data);
                              setLevel4Disabled(false);
                            });
                        }
                      });
                  }
                });
            }
          });
      }
      // });
    }
    getSelectedLocation();
    genToken();
  }, [props.countryName, districtName, stateName, level3Data, level4Data]);

  const handleExploreDatasetClick = () => {
    setExploreDataset(true);
  };
  let countryObject = [];
  let stateObject = [];
  let districtObject = [];
  let level3Object = [];
  let level4Object = [];

  // for (let j in countries) {
  //   let items = {};
  //   items["label"] = countries[j];
  //   items["value"] = countries[j];
  //   const jsonString = JSON.stringify(Object.assign({}, items));
  //   const json_obj = JSON.parse(jsonString);
  //   countryObject.push(json_obj);
  // }
  availableCountries.forEach((country) => {
    let items = {};
    items["label"] = country;
    items["value"] = country;
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    countryObject.push(json_obj);
  });
  for (let j in states) {
    let items = {};
    items["label"] = states[j];
    items["value"] = states[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    stateObject.push(json_obj);
  }
  for (let j in districts) {
    let items = {};
    items["label"] = districts[j];
    items["value"] = districts[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    districtObject.push(json_obj);
  }
  for (let j in level3) {
    let items = {};
    items["label"] = level3[j];
    items["value"] = level3[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    level3Object.push(json_obj);
  }
  for (let j in level4) {
    let items = {};
    items["label"] = level4[j];
    items["value"] = level4[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    level4Object.push(json_obj);
  }
  const getLevel2Placeholder = () => {
    if (props.countryName === "Sudan") {
      return "Select State";
    } else if (props.countryName === "Tanzania") {
      return "Select State";
    } else if (props.countryName === "Zambia") {
      return "Select Province";
    } else if (props.countryName === "Bangladesh") {
      return "Select Division";
    } else if (props.countryName === "Kenya") {
      return "Select County";
    } else {
      return "Select State";
    }
  };
  const getLeveldistrictPlaceholder = () => {
    if (props.countryName === "Bangladesh") {
      return "Select Zila";
    }
    if (props.countryName === "Kenya") {
      return "Select Constituency";
    } else {
      return "Select District";
    }
  };
  const getLevel3Placeholder = () => {
    if (
      props.countryName === "Tanzania" ||
      props.countryName === "Kenya" ||
      props.countryName === "Sudan"
    ) {
      return "Select Ward";
    } else if (props.countryName === "Zambia") {
      return "Select Block";
    } else if (props.countryName === "Bangladesh") {
      return "Select Upazila";
    } else {
      return "Select Level 3";
    }
  };
  const getLevel4Placeholder = () => {
    if (props.countryName === "Zambia") {
      return "Select camp";
    } else if (props.countryName === "Bangladesh") {
      return "Select Union";
    } else {
      return "Select Level 4";
    }
  };
  const getSelectedLocation = () => {
    let selectedLocation = "Location";
    if (props.countryName) {
      selectedLocation = props.countryName;
      if (stateName) {
        selectedLocation += `, ${stateName}`;
        if (districtName) {
          selectedLocation += `, ${districtName}`;
          if (level3Data) {
            selectedLocation += `, ${level3Data}`;
            if (level4Data) {
              selectedLocation += `, ${level4Data}`;
            }
          }
        }
      }
    }
    return selectedLocation;
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "orange" : base.borderColor,
      "&:hover": {
        borderColor: state.isFocused ? "orange" : base.borderColor,
      },
      minHeight: 28,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: 4,
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "0px 6px",
    }),
  };
  const handleResetFields = () => {
    removeLayerByName(map, "AdministrativeBoundary");
    props.setCountryName("");
    dispatch(changeStateName(null));
    dispatch(changeDistrictName(null));
    dispatch(changeLevel3Name(null));
    dispatch(changeLevel4Name(null));
  };

  const isExploreDisabled = () => {
    if (
      props.countryName === "Tanzania" ||
      props.countryName === "Kenya" ||
      props.countryName === "Sudan"
    ) {
      return !stateName;
    } else if (props.countryName === "Zambia") {
      return !stateName;
    } else if (props.countryName === "Bangladesh") {
      return !stateName;
    }
    return false;
  };
  return (
    <>
      <div className="administrative-boundary">
        <div>
          <div className="exploretext">
            <img
              src="chevron-left.png"
              alt="location icon"
              style={{ marginRight: 10 }}
              onClick={() => props.setShowAdministrativeBoundary(false)}
            />
            Administrative boundary
          </div>
          <div className="exploresecondarytext">
            Select a region or location to start exploring datasets. Choose from
            one of the options below.
          </div>
          <div className="dropdown-container">
            <Select
              className="custom-select"
              value={
                props.countryName
                  ? { label: props.countryName, value: props.countryName }
                  : null
              }
              onChange={(obj) => {
                props.setCountryName(obj.value);
                dispatch(changeStateName(null));
                dispatch(changeDistrictName(null));
                dispatch(changeLevel1Name(null));
                dispatch(changeLevel2Name(null));
                dispatch(changeLevel3Name(null));
                dispatch(changeLevel4Name(null));
              }}
              options={countryObject}
              placeholder="Select Country"
            />
          </div>
          <div className="dropdown-container">
            <Select
              className="custom-select"
              value={{
                value: stateName,
                label: stateName
                  ? stateName
                  : getLevel2Placeholder(props.countryName),
              }}
              options={stateObject}
              onChange={(obj) => {
                dispatch(changeStateName(obj["value"]));
                dispatch(changeDistrictName(null));
                dispatch(changeLevel1Name(obj["value"]));
                dispatch(changeLevel2Name(null));
                dispatch(changeLevel3Name(null));
                dispatch(changeLevel4Name(null));
              }}
              styles={customStyles}
              placeholder="Select State"
              isDisabled={stateDisabled}
            />
          </div>
          <div className="dropdown-container">
            <Select
              className="custom-select"
              value={{
                value: districtName,
                label: districtName
                  ? districtName
                  : getLeveldistrictPlaceholder(props.countryName),
              }}
              onChange={(obj) => {
                dispatch(changeDistrictName(obj["value"]));
                dispatch(changeLevel2Name(obj["value"]));
                dispatch(changeLevel3Name(null));
                dispatch(changeLevel4Name(null));
              }}
              options={districtObject}
              styles={customStyles}
              placeholder="Select District"
              isDisabled={districtDisabled}
            />
          </div>
          <div className="dropdown-container">
            <Select
              className="custom-select"
              value={{
                value: level3Data,
                label: level3Data
                  ? level3Data
                  : getLevel3Placeholder(props.countryName),
              }}
              onChange={(obj) => {
                dispatch(changeLevel3Name(obj["value"]));
                dispatch(changeLevel4Name(null));
              }}
              options={level3Object}
              placeholder="Level 3"
              styles={customStyles}
              isDisabled={level3Disabled}
            />
          </div>
          <div className="dropdown-container">
            <Select
              className="custom-select"
              value={{
                value: level4Data,
                label: level4Data
                  ? level4Data
                  : getLevel4Placeholder(props.countryName),
              }}
              onChange={(obj) => {
                dispatch(changeLevel4Name(obj["value"]));
              }}
              options={level4Object}
              placeholder="Level 4"
              styles={customStyles}
              isDisabled={level4Disabled}
            />
          </div>
        </div>
        <div className="exploredataset-button-container">
          <div>
            <hr className="horizontal-rule" />
            <button className="selectedloc-button">
              <img src="loc-pin.png" alt="icon" style={{ marginRight: 5 }} />
              {getSelectedLocation()}
              {/* {selectedLocation} */}

              <img
                src="tabler-icon-x.svg"
                className="reset-button"
                alt="Reset"
                onClick={handleResetFields}
              />
            </button>
          </div>

          <div>
            <button
              className="exploredataset-button"
              onClick={handleExploreDatasetClick}
              disabled={isExploreDisabled()}
            >
              Explore Datasets
            </button>
          </div>
        </div>
      </div>

      {exploreDataset && (
        <VisualDataset
          removeNDVILayers={props.removeNDVILayers}
          tamsatDailyGraph={props.tamsatDailyGraph}
          setTamsatDailyGraph={props.setTamsatDailyGraph}
          tamsatMonthlyGraph={props.tamsatMonthlyGraph}
          setTamsatMonthlyGraph={props.setTamsatMonthlyGraph}
          chirps5kmMonthlyRainfall={props.chirps5kmMonthlyRainfall}
          setChirps5kmMonthlyRainfall={props.setChirps5kmMonthlyRainfall}
          arc210kmDailyRainfall={props.arc210kmDailyRainfall}
          setarc210kmDailyRainfall={props.setarc210kmDailyRainfall}
          modis5DayIntervalNDVIGraph={props.modis5DayIntervalNDVIGraph}
          setModis5DayIntervalNDVIGraph={props.setModis5DayIntervalNDVIGraph}
          setExploreDataset={setExploreDataset}
          exploreDataset={exploreDataset}
          landcover={props.landcover}
          setlandcover={props.setlandcover}
          chirps5kmDailyRainfall={props.chirps5kmDailyRainfall}
          setChirps5kmDailyRainfall={props.setChirps5kmDailyRainfall}
          era5DailyRainfallGraph={props.era5DailyRainfallGraph}
          setera5DailyRainfallGraph={props.setera5DailyRainfallGraph}
          era5MonthlyRainfallGraph={props.era5MonthlyRainfallGraph}
          setera5MonthlyRainfallGraph={props.setera5MonthlyRainfallGraph}
          era5DailyTemperatureGraph={props.era5DailyTemperatureGraph}
          setera5DailyTemperatureGraph={props.setera5DailyTemperatureGraph}
          era5MonthlyTemperatureGraph={props.era5MonthlyTemperatureGraph}
          setera5MonthlyTemperatureGraph={props.setera5MonthlyTemperatureGraph}
          gsMapDaily={props.gsMapDaily}
          setgsMapDaily={props.setgsMapDaily}
          gsMapMonthly={props.gsMapMonthly}
          setgsMapMonthly={props.setgsMapMonthly}
          sentinal2NDVI={props.sentinal2NDVI}
          setsentinal2NDVI={props.setsentinal2NDVI}
          getSelectedLocation={getSelectedLocation}
          selectedLocation={selectedLocation}
          countryName={props.countryName}
          setIsDrawerOpen={props.setIsDrawerOpen}
          forecastedChirpsDailyRainfallGraph={props.forecastedChirpsDailyRainfallGraph}
          setforecastedChirpsDailyRainfallGraph={props.setforecastedChirpsDailyRainfallGraph}
        />
      )}
    </>
  );
};

export default AdministrativeBoundary;
