import React, { useState, useEffect } from "react";
import "../../styles.css";
import { Auth } from "aws-amplify";
import { ColumnChart } from "./ColumnChart";
import { LineChart } from "./LineChart";
import { NdviGraph } from "./NdviGraph";
import { useDispatch, useSelector } from "react-redux";
import {
  changeModisNDVILayer,
  changeLandcoverLayer,
  changeSentinel2NDVILayer,
  changeshowModisNDVIDot,
  changeshowSentinelNDVIDot,
} from "../../redux/action";
import PieChart from "./PieChart";
import { LineChartTemp } from "./LineChartTemp";
import { ColumnChartTemp } from "./ColumnChartTemp";
import { MdGridOn } from "react-icons/md";
import CircularProgress from "@mui/material/CircularProgress";
import { useMap } from "../../hooks/useMap";
import GeoJSON from "ol/format/GeoJSON";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ.js";

const MapDrawer = (props) => {
  const stateName = useSelector((state) => state.stateName);
  const districtName = useSelector((state) => state.districtName);
  const level4Data = useSelector((state) => state.level4Data);
  const level3Data = useSelector((state) => state.level3Data);
  const [isExpanded, setIsExpanded] = useState(false);
  const [chirpsMonthlyRainfall, setChirpsMonthlyRainfall] = useState([]);
  const [chirpsDailyRainfall, setChirpsDailyRainfall] = useState([]);
  const [arc2DailyRainfall, setArc2DailyRainfall] = useState([]);
  const [tamsatDailyRainfall, setTamsatDailyRainfall] = useState([]);
  const [tamsatMonthlyRainfall, setTamsatMonthlyRainfall] = useState([]);
  const [modisNDVI, setmodisNDVI] = useState([]);
  const [landcovergraph, setlandcovergraph] = useState([]);
  const [era5DailyRainfallgraph, setera5DailyRainfallgraph] = useState([]);
  const [era5MonthlyRainfallgraph, setera5MonthlyRainfallgraph] = useState([]);
  const [era5DailyTemperaturegraph, setera5DailyTemperaturegraph] = useState(
    []
  );
  const [era5MonthlyTemperaturegraph, setera5MonthlyTemperaturegraph] =
    useState([]);
  const [forecastedChirpsDailyRainfallgraph, setforecastedChirpsDailyRainfallgraph] = useState([]);
  const [gsMapDailygraph, setgsMapDailygraph] = useState([]);
  const [gsMapMonthlygraph, setgsMapMonthlygraph] = useState([]);
  const [sentinal2NDVIgraph, setsentinal2NDVIgraph] = useState([]);
  const [chirpsdailyLoading, setchirpsdailyLoading] = useState(false);
  const [chirpsmonthlyLoading, setchirpsmonthlyLoading] = useState(false);
  const [tamsatdailyLoading, settamsatdailyLoading] = useState(false);
  const [tamsatmonthlyLoading, settamsatmonthlyLoading] = useState(false);
  const [modisloading, setmodisLoading] = useState(false);
  const [sentinal2Loading, setsentinal2Loading] = useState(false);
  const modisNdviLayer = useSelector((state) => state.modisNdviLayer);
  const sentinel2NdviLayer = useSelector((state) => state.sentinel2NdviLayer);
  const landcoverLayer = useSelector((state) => state.landcoverLayer);
  const showModisNDVIDot = useSelector((state) => state.showModisNDVIDot);
  const showSentinel2NDVIDot = useSelector(
    (state) => state.showSentinel2NDVIDot
  );
  const [gsmapdailyLoading, setgsmapdailyLoading] = useState(false);
  const [gsmapmonthlyLoading, setgsmapmonthlyLoading] = useState(false);
  const [era5dailytempoading, setera5dailytempoading] = useState(false);
  const [era5monthlytempoading, setera5monthlytempoading] = useState(false);
  const [era5dailyrainfallloading, setera5dailyrainfallloading] =
    useState(false);
  const [era5monthlyrainfallloading, setera5monthlyrainfallloading] =
    useState(false);
  const [forecastedChirpsDailyRainfallGraphloading, setforecastedChirpsDailyRainfallGraphloading] =useState(false);
  const [landcoverloading, setlandcoverloading] = useState(false);
  const [arc2dailyLoading, setarc2dailyLoading] = useState(false);
  const [showLandcoverDot, setShowLandcoverDot] = useState(false);
  const { fishnetLayer, setFishnetLayer, map } = useMap();
  const [fishnetSwitchState, setFishnetSwitchState] = useState(false);
  const [dailyfishnetSwitchState, setdailyFishnetSwitchState] = useState(false);
  const [pixelChirpsMonthlyRainfall, setPixelChirpsMonthlyRainfall] = useState([]);
  const [pixelChirpsDailyRainfall, setPixelChirpsDailyRainfall] = useState([]);
  const [pixelChirpsDailyEmpty, setPixelChirpsDailyEmpty] = useState(false);
  const [pixelChirpsMonthlyEmpty, setPixelChirpsMonthlyEmpty] = useState(false);

  const [pixelChirpsMonthlyLoading, setPixelChirpsMonthlyLoading] =
    useState(false);
  const [ndvilayeroverlayLoading, setndvilayeroverlayLoading] = useState(false);
  const [pixelChirpsDailyLoading, setPixelChirpsDailyLoading] = useState(false);
  const dispatch = useDispatch();
  const [addedLayers, setAddedLayers] = useState(null);
  const pixelID = useSelector((state) => state.pixelID);
  const pixelName = useSelector((state) => state.pixelName);
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().slice(0, 10);

  useEffect(() => {
    const fetchChirpsData = async () => {
      const { accessToken: { jwtToken } } = await Auth.currentSession();
      let url1 = `/api/explore/chirpsMonthly?country=${props.countryName}`;
  
      if (props.stateName) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
            url1 += `&state=${props.stateName}`;
            break;
          case "Zambia":
            url1 += `&province=${props.stateName}`;
            break;
          case "Kenya":
            url1 += `&county=${props.stateName}`;
            break;
          case "Bangladesh":
            url1 += `&division=${props.stateName}`;
            break;
          default:
            break;
        }
      }
  
      if (props.districtName) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
            url1 += `&district=${props.districtName}`;
            break;
          case "Zambia":
            url1 += `&district=${props.districtName}`;
            break;
          case "Kenya":
            url1 += `&constituency=${props.districtName}`;
            break;
          case "Bangladesh":
            url1 += `&zila=${props.districtName}`;
            break;
          default:
            break;
        }
      }
  
      if (props.level3Data) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
          case "Kenya":
            url1 += `&ward=${props.level3Data}`;
            break;
          case "Zambia":
            url1 += `&block=${props.level3Data}`;
          case "Bangladesh":
            url1 += `&upazila=${props.level3Data}`;
            break;
          default:
            break;
        }
      }
  
      if (props.level4Data) {
        switch (props.countryName) {
          case "Zambia":
            url1 += `&camp=${props.level4Data}`;
            break;
          case "Bangladesh":
            url1 += `&union=${props.level4Data}`;
            break;
          default:
            break;
        }
      }
  
      if (props.chirps5kmMonthlyRainfall) {
        setChirpsMonthlyRainfall([]);
        setchirpsmonthlyLoading(true);
  
        try {
          const response = await fetch(url1, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          });
  
          const data = await response.json();
          setChirpsMonthlyRainfall(data);
        } catch (error) {
          console.error("Error fetching data:", error);
          setChirpsMonthlyRainfall([]);
        } finally {
          setchirpsmonthlyLoading(false);
        }
      }
    };
  
    fetchChirpsData();
  }, [
    props.level2Data,
    props.level3Data,
    props.level4Data,
    props.countryName,
    props.stateName,
    props.chirps5kmMonthlyRainfall,
  ]);
  
  
  useEffect(() => {
    const fetchArc2Data = async () => {
      const { accessToken: { jwtToken } } = await Auth.currentSession();
      let url2 = `/api/explore/arc2Daily?country=${props.countryName}`;
  
      if (props.stateName) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
            url2 += `&state=${props.stateName}`;
            break;
          case "Zambia":
            url2 += `&province=${props.stateName}`;
            break;
          case "Kenya":
            url2 += `&county=${props.stateName}`;
            break;
          case "Bangladesh":
            url2 += `&division=${props.stateName}`;
            break;
          default:
            break;
        }
      }
  
      if (props.districtName) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
            url2 += `&district=${props.districtName}`;
            break;
          case "Zambia":
            url2 += `&district=${props.districtName}`;
            break;
          case "Kenya":
            url2 += `&constituency=${props.districtName}`;
            break;
          case "Bangladesh":
            url2 += `&zila=${props.districtName}`;
            break;
          default:
            break;
        }
      }
  
      if (props.level3Data) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
          case "Kenya":
            url2 += `&ward=${props.level3Data}`;
            break;
          case "Zambia":
            url2 += `&block=${props.level3Data}`;
          case "Bangladesh":
            url2 += `&upazila=${props.level3Data}`;
            break;
          default:
            break;
        }
      }
  
      if (props.level4Data) {
        switch (props.countryName) {
          case "Zambia":
            url2 += `&camp=${props.level4Data}`;
            break;
          case "Bangladesh":
            url2 += `&union=${props.level4Data}`;
            break;
          default:
            break;
        }
      }
  
      if (props.arc210kmDailyRainfall) {
        setarc2dailyLoading(true);
        try {
          const response = await fetch(url2, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          });
  
          const data = await response.json();
          setArc2DailyRainfall(data);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setarc2dailyLoading(false);
        }
      }
    };
  
    fetchArc2Data();
  }, [
    props.level2Data,
    props.level3Data,
    props.level4Data,
    props.countryName,
    props.stateName,
    props.arc210kmDailyRainfall,
  ]);

  useEffect(() => {
    const fetchTamsatData = async () => {
      const { accessToken: { jwtToken } } = await Auth.currentSession();
      let url3 = `/api/explore/tamsatDaily?country=${props.countryName}`;
  
      if (props.stateName) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
            url3 += `&state=${props.stateName}`;
            break;
          case "Zambia":
            url3 += `&province=${props.stateName}`;
            break;
          case "Kenya":
            url3 += `&county=${props.stateName}`;
            break;
          case "Bangladesh":
            url3 += `&division=${props.stateName}`;
            break;
          default:
            break;
        }
      }
  
      if (props.districtName) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
            url3 += `&district=${props.districtName}`;
            break;
          case "Zambia":
            url3 += `&district=${props.districtName}`;
            break;
          case "Kenya":
            url3 += `&constituency=${props.districtName}`;
            break;
          case "Bangladesh":
            url3 += `&zila=${props.districtName}`;
            break;
          default:
            break;
        }
      }
  
      if (props.level3Data) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
          case "Kenya":
            url3 += `&ward=${props.level3Data}`;
            break;
          case "Zambia":
            url3 += `&block=${props.level3Data}`;
          case "Bangladesh":
            url3 += `&upazila=${props.level3Data}`;
            break;
          default:
            break;
        }
      }
  
      if (props.level4Data) {
        switch (props.countryName) {
          case "Zambia":
            url3 += `&camp=${props.level4Data}`;
            break;
          case "Bangladesh":
            url3 += `&union=${props.level4Data}`;
            break;
          default:
            break;
        }
      }
  
      if (props.tamsatDailyGraph) {
        settamsatdailyLoading(true);
  
        try {
          const response = await fetch(url3, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          });
  
          const data = await response.json();
          setTamsatDailyRainfall(data);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          settamsatdailyLoading(false);
        }
      }
    };
  
    fetchTamsatData();
  }, [
    props.level2Data,
    props.level3Data,
    props.level4Data,
    props.countryName,
    props.stateName,
    props.tamsatDailyGraph,
  ]);
  
  useEffect(() => {
    const fetchTamsatMonthlyData = async () => {
      const { accessToken: { jwtToken } } = await Auth.currentSession();
      let url4 = `/api/explore/tamsatMonthly?country=${props.countryName}`;
  
      if (props.stateName) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
            url4 += `&state=${props.stateName}`;
            break;
          case "Zambia":
            url4 += `&province=${props.stateName}`;
            break;
          case "Kenya":
            url4 += `&county=${props.stateName}`;
            break;
          case "Bangladesh":
            url4 += `&division=${props.stateName}`;
            break;
          default:
            break;
        }
      }
  
      if (props.districtName) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
            url4 += `&district=${props.districtName}`;
            break;
          case "Zambia":
            url4 += `&district=${props.districtName}`;
            break;
          case "Kenya":
            url4 += `&constituency=${props.districtName}`;
            break;
          case "Bangladesh":
            url4 += `&zila=${props.districtName}`;
            break;
          default:
            break;
        }
      }
  
      if (props.level3Data) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
          case "Kenya":
            url4 += `&ward=${props.level3Data}`;
            break;
          case "Zambia":
            url4 += `&block=${props.level3Data}`;
          case "Bangladesh":
            url4 += `&upazila=${props.level3Data}`;
            break;
          default:
            break;
        }
      }
  
      if (props.level4Data) {
        switch (props.countryName) {
          case "Zambia":
            url4 += `&camp=${props.level4Data}`;
            break;
          case "Bangladesh":
            url4 += `&union=${props.level4Data}`;
            break;
          default:
            break;
        }
      }
  
      if (props.tamsatMonthlyGraph) {
        settamsatmonthlyLoading(true);
  
        try {
          const response = await fetch(url4, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          });
  
          const data = await response.json();
          setTamsatMonthlyRainfall(data);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          settamsatmonthlyLoading(false);
        }
      }
    };
  
    fetchTamsatMonthlyData();
  }, [
    props.level2Data,
    props.level3Data,
    props.level4Data,
    props.countryName,
    props.stateName,
    props.tamsatMonthlyGraph,
  ]);
  
  useEffect(() => {
    const fetchModisNDVIData = async () => {
      const { accessToken: { jwtToken } } = await Auth.currentSession();
      let url5 = `/api/explore/modisNDVI?country=${props.countryName}`;
  
      if (props.stateName) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
            url5 += `&state=${props.stateName}`;
            break;
          case "Zambia":
            url5 += `&province=${props.stateName}`;
            break;
          case "Kenya":
            url5 += `&county=${props.stateName}`;
            break;
          case "Bangladesh":
            url5 += `&division=${props.stateName}`;
            break;
          default:
            break;
        }
      }
  
      if (props.districtName) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
            url5 += `&district=${props.districtName}`;
            break;
          case "Zambia":
            url5 += `&district=${props.districtName}`;
            break;
          case "Kenya":
            url5 += `&constituency=${props.districtName}`;
            break;
          case "Bangladesh":
            url5 += `&zila=${props.districtName}`;
            break;
          default:
            break;
        }
      }
  
      if (props.level3Data) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
          case "Kenya":
            url5 += `&ward=${props.level3Data}`;
            break;
          case "Zambia":
            url5 += `&block=${props.level3Data}`;
          case "Bangladesh":
            url5 += `&upazila=${props.level3Data}`;
            break;
          default:
            break;
        }
      }
  
      if (props.level4Data) {
        switch (props.countryName) {
          case "Zambia":
            url5 += `&camp=${props.level4Data}`;
            break;
          case "Bangladesh":
            url5 += `&union=${props.level4Data}`;
            break;
          default:
            break;
        }
      }
  
      if (props.modis5DayIntervalNDVIGraph) {
        setmodisLoading(true);
  
        try {
          const response = await fetch(url5, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          });
  
          const data = await response.json();
          setmodisNDVI(data);
        } catch (error) {
          console.error("Error fetching data:", error);
          setmodisNDVI([]);
        } finally {
          setmodisLoading(false);
        }
      }
    };
  
    fetchModisNDVIData();
  }, [
    props.level2Data,
    props.level3Data,
    props.level4Data,
    props.countryName,
    props.stateName,
    props.modis5DayIntervalNDVIGraph,
  ]);  

  useEffect(() => {
    const fetchLandCoverData = async () => {
      const { accessToken: { jwtToken } } = await Auth.currentSession();
      let url6 = `/api/explore/landCoverPercent?country=${props.countryName}&level1=${props.stateName}`;
  
      if (props.districtName) {
        url6 += `&level2=${props.districtName}`;
      }
  
      if (props.level3Data) {
        url6 += `&level3=${props.level3Data}`;
      }
  
      if (props.level4Data) {
        url6 += `&level4=${props.level4Data}`;
      }
  
      if (props.landcover) {
        setlandcoverloading(true);
  
        try {
          const response = await fetch(url6, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          });
  
          const data = await response.json();
          setlandcovergraph(data);
        } catch (error) {
          console.error("Error fetching data:", error);
          setlandcovergraph(null);
        } finally {
          setlandcoverloading(false);
        }
      }
    };
  
    fetchLandCoverData();
  }, [
    props.level2Data,
    props.level3Data,
    props.level4Data,
    props.countryName,
    props.stateName,
    props.landcover,
  ]);  

  useEffect(() => {
    const fetchEra5DailyRainfall = async () => {
      const { accessToken: { jwtToken } } = await Auth.currentSession();
      let url7 = `/api/explore/era5DailyRainfall?country=${props.countryName}`;
  
      if (props.stateName) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
            url7 += `&state=${props.stateName}`;
            break;
          case "Zambia":
            url7 += `&province=${props.stateName}`;
            break;
          case "Kenya":
            url7 += `&state=${props.stateName}`;
            break;
          case "Bangladesh":
            url7 += `&division=${props.stateName}`;
            break;
          default:
            break;
        }
      }
  
      if (props.districtName) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
            url7 += `&district=${props.districtName}`;
            break;
          case "Zambia":
            url7 += `&district=${props.districtName}`;
            break;
          case "Kenya":
            url7 += `&constituency=${props.districtName}`;
            break;
          case "Bangladesh":
            url7 += `&zila=${props.districtName}`;
            break;
          default:
            break;
        }
      }
  
      if (props.level3Data) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
          case "Kenya":
            url7 += `&ward=${props.level3Data}`;
            break;
          case "Zambia":
            url7 += `&block=${props.level3Data}`;
          case "Bangladesh":
            url7 += `&upazila=${props.level3Data}`;
            break;
          default:
            break;
        }
      }
  
      if (props.level4Data) {
        switch (props.countryName) {
          case "Zambia":
            url7 += `&camp=${props.level4Data}`;
            break;
          case "Bangladesh":
            url7 += `&union=${props.level4Data}`;
            break;
          default:
            break;
        }
      }
  
      if (props.era5DailyRainfallGraph) {
        setera5dailyrainfallloading(true);
  
        try {
          const response = await fetch(url7, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          });
  
          const data = await response.json();
          setera5DailyRainfallgraph(data);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setera5dailyrainfallloading(false);
        }
      }
    };
  
    fetchEra5DailyRainfall();
  }, [
    props.level2Data,
    props.level3Data,
    props.level4Data,
    props.countryName,
    props.stateName,
    props.era5DailyRainfallGraph,
  ]);  

  useEffect(() => {
    const fetchEra5MonthlyRainfall = async () => {
      try {
        const { accessToken: { jwtToken } } = await Auth.currentSession(); // Secure token retrieval
        let url8 = `/api/explore/era5MonthlyRainfall?country=${props.countryName}`;
  
        if (props.stateName) {
          switch (props.countryName) {
            case "Tanzania":
            case "Sudan":
              url8 += `&state=${props.stateName}`;
              break;
            case "Zambia":
              url8 += `&province=${props.stateName}`;
              break;
            case "Kenya":
              url8 += `&state=${props.stateName}`;
              break;
            case "Bangladesh":
              url8 += `&division=${props.stateName}`;
              break;
            default:
              break;
          }
        }
  
        if (props.districtName) {
          switch (props.countryName) {
            case "Tanzania":
            case "Sudan":
              url8 += `&district=${props.districtName}`;
              break;
            case "Zambia":
              url8 += `&district=${props.districtName}`;
              break;
            case "Kenya":
              url8 += `&constituency=${props.districtName}`;
              break;
            case "Bangladesh":
              url8 += `&zila=${props.districtName}`;
              break;
            default:
              break;
          }
        }
  
        if (props.level3Data) {
          switch (props.countryName) {
            case "Tanzania":
            case "Sudan":
            case "Kenya":
              url8 += `&ward=${props.level3Data}`;
              break;
            case "Zambia":
              url8 += `&block=${props.level3Data}`;
            case "Bangladesh":
              url8 += `&upazila=${props.level3Data}`;
              break;
            default:
              break;
          }
        }
  
        if (props.level4Data) {
          switch (props.countryName) {
            case "Zambia":
              url8 += `&camp=${props.level4Data}`;
              break;
            case "Bangladesh":
              url8 += `&union=${props.level4Data}`;
              break;
            default:
              break;
          }
        }
  
        if (props.era5MonthlyRainfallGraph) {
          setera5monthlyrainfallloading(true);
          const response = await fetch(url8, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          });
  
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
  
          const data = await response.json();
          setera5MonthlyRainfallgraph(data);
        }
      } catch (error) {
        console.error("Error fetching era5MonthlyRainfall data:", error);
      } finally {
        setera5monthlyrainfallloading(false);
      }
    };
  
    fetchEra5MonthlyRainfall();
  }, [
    props.level2Data,
    props.level3Data,
    props.level4Data,
    props.countryName,
    props.stateName,
    props.era5MonthlyRainfallGraph,
  ]);

 useEffect(() => {
  const fetchEra5DailyTemperature = async () => {
    try {
      const { accessToken: { jwtToken } } = await Auth.currentSession(); // Securely fetch JWT token

      let url9 = `/api/explore/era5DailyTemperature?country=${props.countryName}`;

      // Build URL based on the provided props and country-specific keys
      if (props.stateName) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
          case "Kenya":
            url9 += `&state=${props.stateName}`;
            break;
          case "Zambia":
            url9 += `&province=${props.stateName}`;
            break;
          case "Bangladesh":
            url9 += `&division=${props.stateName}`;
            break;
          default:
            break;
        }
      }

      if (props.districtName) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
          case "Zambia":
            url9 += `&district=${props.districtName}`;
            break;
          case "Kenya":
            url9 += `&constituency=${props.districtName}`;
            break;
          case "Bangladesh":
            url9 += `&zila=${props.districtName}`;
            break;
          default:
            break;
        }
      }

      if (props.level3Data) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
          case "Kenya":
            url9 += `&ward=${props.level3Data}`;
            break;
          case "Zambia":
            url9 += `&block=${props.level3Data}`;
          case "Bangladesh":
            url9 += `&upazila=${props.level3Data}`;
            break;
          default:
            break;
        }
      }

      if (props.level4Data) {
        switch (props.countryName) {
          case "Zambia":
            url9 += `&camp=${props.level4Data}`;
            break;
          case "Bangladesh":
            url9 += `&union=${props.level4Data}`;
            break;
          default:
            break;
        }
      }

      // Fetch data if graph property is true
      if (props.era5DailyTemperatureGraph) {
        setera5dailytempoading(true);

        const response = await fetch(url9, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setera5DailyTemperaturegraph(data);
      }
    } catch (error) {
      console.error("Error fetching era5DailyTemperature data:", error);
      setera5DailyTemperaturegraph(null);
    } finally {
      setera5dailytempoading(false);
    }
  };

  fetchEra5DailyTemperature();
}, [
  props.level2Data,
  props.level3Data,
  props.level4Data,
  props.countryName,
  props.stateName,
  props.era5DailyTemperatureGraph,
]);

useEffect(() => {
  const fetchEra5MonthlyTemperature = async () => {
    try {
      const { accessToken: { jwtToken } } = await Auth.currentSession(); // Securely fetch JWT token

      let url10 = `/api/explore/era5MonthlyTemperature?country=${props.countryName}`;

      // Construct URL based on props and country-specific keys
      if (props.stateName) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
          case "Kenya":
            url10 += `&state=${props.stateName}`;
            break;
          case "Zambia":
            url10 += `&province=${props.stateName}`;
            break;
          case "Bangladesh":
            url10 += `&division=${props.stateName}`;
            break;
          default:
            break;
        }
      }

      if (props.districtName) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
          case "Zambia":
            url10 += `&district=${props.districtName}`;
            break;
          case "Kenya":
            url10 += `&constituency=${props.districtName}`;
            break;
          case "Bangladesh":
            url10 += `&zila=${props.districtName}`;
            break;
          default:
            break;
        }
      }

      if (props.level3Data) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
          case "Kenya":
            url10 += `&ward=${props.level3Data}`;
            break;
          case "Zambia":
            url10 += `&block=${props.level3Data}`;
          case "Bangladesh":
            url10 += `&upazila=${props.level3Data}`;
            break;
          default:
            break;
        }
      }

      if (props.level4Data) {
        switch (props.countryName) {
          case "Zambia":
            url10 += `&camp=${props.level4Data}`;
            break;
          case "Bangladesh":
            url10 += `&union=${props.level4Data}`;
            break;
          default:
            break;
        }
      }

      // Fetch data if graph property is true
      if (props.era5MonthlyTemperatureGraph) {
        setera5monthlytempoading(true);

        const response = await fetch(url10, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setera5MonthlyTemperaturegraph(data);
      }
    } catch (error) {
      console.error("Error fetching era5MonthlyTemperature data:", error);
      setera5MonthlyTemperaturegraph(null);
    } finally {
      setera5monthlytempoading(false);
    }
  };

  fetchEra5MonthlyTemperature();
}, [
  props.level2Data,
  props.level3Data,
  props.level4Data,
  props.countryName,
  props.stateName,
  props.era5MonthlyTemperatureGraph,
]);

useEffect(() => {
  const fetchChirpsDailyRainfall = async () => {
    try {
      const { accessToken: { jwtToken } } = await Auth.currentSession(); // Securely fetch JWT token

      let url11 = `/api/explore/chirpsDaily?country=${props.countryName}`;

      // Construct URL based on props and country-specific keys
      if (props.stateName) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
            url11 += `&state=${props.stateName}`;
            break;
          case "Zambia":
            url11 += `&province=${props.stateName}`;
            break;
          case "Kenya":
            url11 += `&county=${props.stateName}`;
            break;
          case "Bangladesh":
            url11 += `&division=${props.stateName}`;
            break;
          default:
            break;
        }
      }

      if (props.districtName) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
            url11 += `&district=${props.districtName}`;
            break;
          case "Zambia":
            url11 += `&district=${props.districtName}`;
            break;
          case "Kenya":
            url11 += `&constituency=${props.districtName}`;
            break;
          case "Bangladesh":
            url11 += `&zila=${props.districtName}`;
            break;
          default:
            break;
        }
      }

      if (props.level3Data) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
          case "Kenya":
            url11 += `&ward=${props.level3Data}`;
            break;
          case "Zambia":
            url11 += `&block=${props.level3Data}`;
          case "Bangladesh":
            url11 += `&upazila=${props.level3Data}`;
            break;
          default:
            break;
        }
      }

      if (props.level4Data) {
        switch (props.countryName) {
          case "Zambia":
            url11 += `&camp=${props.level4Data}`;
            break;
          case "Bangladesh":
            url11 += `&union=${props.level4Data}`;
            break;
          default:
            break;
        }
      }

      // Proceed to fetch if the condition is true
      if (props.chirps5kmDailyRainfall || dailyfishnetSwitchState === false) {
        setchirpsdailyLoading(true);
        setChirpsDailyRainfall([]); // Reset state before fetching

        const response = await fetch(url11, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setChirpsDailyRainfall(data); // Store the fetched data
      }
    } catch (error) {
      console.error("Error fetching CHIRPS daily rainfall data:", error);
      setChirpsDailyRainfall([]); // Reset state on error
    } finally {
      setchirpsdailyLoading(false); // Ensure loading state is reset
    }
  };

  fetchChirpsDailyRainfall();
}, [
  props.level2Data,
  props.level3Data,
  props.level4Data,
  props.countryName,
  props.stateName,
  props.chirps5kmDailyRainfall,
  dailyfishnetSwitchState,
]);

// Fetch GS Map Daily Data
useEffect(() => {
  const fetchGsMapDaily = async () => {
    try {
      const { accessToken: { jwtToken } } = await Auth.currentSession(); // Securely fetch JWT token

      let url12 = `/api/explore/gsMapDaily?country=${props.countryName}`;

      // Construct URL based on props and country-specific keys
      if (props.stateName) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
            url12 += `&state=${props.stateName}`;
            break;
          case "Zambia":
            url12 += `&province=${props.stateName}`;
            break;
          case "Kenya":
            url12 += `&county=${props.stateName}`;
            break;
          case "Bangladesh":
            url12 += `&division=${props.stateName}`;
            break;
          default:
            break;
        }
      }

      if (props.districtName) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
            url12 += `&district=${props.districtName}`;
            break;
          case "Zambia":
            url12 += `&district=${props.districtName}`;
            break;
          case "Kenya":
            url12 += `&constituency=${props.districtName}`;
            break;
          case "Bangladesh":
            url12 += `&zila=${props.districtName}`;
            break;
          default:
            break;
        }
      }

      if (props.level3Data) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
          case "Kenya":
            url12 += `&ward=${props.level3Data}`;
            break;
          case "Zambia":
            url12 += `&block=${props.level3Data}`;
          case "Bangladesh":
            url12 += `&upazila=${props.level3Data}`;
            break;
          default:
            break;
        }
      }

      if (props.level4Data) {
        switch (props.countryName) {
          case "Zambia":
            url12 += `&camp=${props.level4Data}`;
            break;
          case "Bangladesh":
            url12 += `&union=${props.level4Data}`;
            break;
          default:
            break;
        }
      }

      // Proceed to fetch if the condition is true
      if (props.gsMapDaily) {
        setgsmapdailyLoading(true);
        setgsMapDailygraph([]); // Reset state before fetching

        const response = await fetch(url12, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setgsMapDailygraph(data); // Store the fetched data
      }
    } catch (error) {
      console.error("Error fetching GS Map Daily data:", error);
      setgsMapDailygraph([]); // Reset state on error
    } finally {
      setgsmapdailyLoading(false); // Ensure loading state is reset
    }
  };

  fetchGsMapDaily();
}, [
  props.level2Data,
  props.level3Data,
  props.level4Data,
  props.countryName,
  props.stateName,
  props.gsMapDaily,
]);

// Fetch GS Map Monthly Data
useEffect(() => {
  const fetchGsMapMonthly = async () => {
    try {
      const { accessToken: { jwtToken } } = await Auth.currentSession(); // Securely fetch JWT token

      let url13 = `/api/explore/gsMapMonthly?country=${props.countryName}`;

      // Construct URL based on props and country-specific keys
      if (props.stateName) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
            url13 += `&state=${props.stateName}`;
            break;
          case "Zambia":
            url13 += `&province=${props.stateName}`;
            break;
          case "Kenya":
            url13 += `&county=${props.stateName}`;
            break;
          case "Bangladesh":
            url13 += `&division=${props.stateName}`;
            break;
          default:
            break;
        }
      }

      if (props.districtName) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
            url13 += `&district=${props.districtName}`;
            break;
          case "Zambia":
            url13 += `&district=${props.districtName}`;
            break;
          case "Kenya":
            url13 += `&constituency=${props.districtName}`;
            break;
          case "Bangladesh":
            url13 += `&zila=${props.districtName}`;
            break;
          default:
            break;
        }
      }

      if (props.level3Data) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
          case "Kenya":
            url13 += `&ward=${props.level3Data}`;
            break;
          case "Zambia":
            url13 += `&block=${props.level3Data}`;
          case "Bangladesh":
            url13 += `&upazila=${props.level3Data}`;
            break;
          default:
            break;
        }
      }

      if (props.level4Data) {
        switch (props.countryName) {
          case "Zambia":
            url13 += `&camp=${props.level4Data}`;
            break;
          case "Bangladesh":
            url13 += `&union=${props.level4Data}`;
            break;
          default:
            break;
        }
      }

      // Proceed to fetch if the condition is true
      if (props.gsMapMonthly) {
        setgsmapmonthlyLoading(true);
        setgsMapMonthlygraph([]); // Reset state before fetching

        const response = await fetch(url13, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setgsMapMonthlygraph(data); // Store the fetched data
      }
    } catch (error) {
      console.error("Error fetching GS Map Monthly data:", error);
      setgsMapMonthlygraph([]); // Reset state on error
    } finally {
      setgsmapmonthlyLoading(false); // Ensure loading state is reset
    }
  };

  fetchGsMapMonthly();
}, [
  props.level2Data,
  props.level3Data,
  props.level4Data,
  props.countryName,
  props.stateName,
  props.gsMapMonthly,
]);


useEffect(() => {
  const fetchSentinel2NDVI = async () => {
    try {
      const { accessToken: { jwtToken } } = await Auth.currentSession(); // Securely fetch JWT token

      let url14 = `/api/explore/sentinel2NDVI?country=${props.countryName}`;

      // Construct URL based on props and country-specific keys
      if (props.stateName) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
            url14 += `&state=${props.stateName}`;
            break;
          case "Zambia":
            url14 += `&province=${props.stateName}`;
            break;
          case "Kenya":
            url14 += `&county=${props.stateName}`;
            break;
          case "Bangladesh":
            url14 += `&division=${props.stateName}`;
            break;
          default:
            break;
        }
      }

      if (props.districtName) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
            url14 += `&district=${props.districtName}`;
            break;
          case "Zambia":
            url14 += `&district=${props.districtName}`;
            break;
          case "Kenya":
            url14 += `&constituency=${props.districtName}`;
            break;
          case "Bangladesh":
            url14 += `&zila=${props.districtName}`;
            break;
          default:
            break;
        }
      }

      if (props.level3Data) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
          case "Kenya":
            url14 += `&ward=${props.level3Data}`;
            break;
          case "Zambia":
            url14 += `&block=${props.level3Data}`;
          case "Bangladesh":
            url14 += `&upazila=${props.level3Data}`;
            break;
          default:
            break;
        }
      }

      if (props.level4Data) {
        switch (props.countryName) {
          case "Zambia":
            url14 += `&camp=${props.level4Data}`;
            break;
          case "Bangladesh":
            url14 += `&union=${props.level4Data}`;
            break;
          default:
            break;
        }
      }

      // Proceed to fetch if the condition is true
      if (props.sentinal2NDVI) {
        setsentinal2Loading(true);
        setsentinal2NDVIgraph([]); // Reset state before fetching

        const response = await fetch(url14, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setsentinal2NDVIgraph(data); // Store the fetched data
      }
    } catch (error) {
      console.error("Error fetching Sentinel-2 NDVI data:", error);
      setsentinal2NDVIgraph([]); // Reset state on error
    } finally {
      setsentinal2Loading(false); // Ensure loading state is reset
    }
  };

  fetchSentinel2NDVI();
}, [
  props.level2Data,
  props.level3Data,
  props.level4Data,
  props.countryName,
  props.stateName,
  props.sentinal2NDVI,
]);

useEffect(() => {
  const fetchPixelChirpsMonthly = async () => {
    try {
      // Fetch JWT token securely
      const { accessToken: { jwtToken } } = await Auth.currentSession();

      let url15 = `/api/explore/pixelData?country=${props.countryName}&pixelName=${pixelName}&dataset=ChirpsMonthly`;

      if (fishnetSwitchState) {
        setPixelChirpsMonthlyLoading(true);
        setPixelChirpsMonthlyRainfall([]);

        if (pixelName !== "") {
          const response = await fetch(url15, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          setPixelChirpsMonthlyRainfall(data);
        } else {
          setPixelChirpsMonthlyEmpty(true);
        }
      }
    } catch (error) {
      setPixelChirpsMonthlyEmpty(true);
      setPixelChirpsMonthlyRainfall([]);
      console.error("Error fetching Pixel Chirps Monthly data:", error);
    } finally {
      setPixelChirpsMonthlyLoading(false);
    }
  };

  fetchPixelChirpsMonthly();
}, [
  props.countryName,
  pixelName,
  props.chirps5kmMonthlyRainfall,
  fishnetSwitchState,
]);

useEffect(() => {
  const fetchPixelChirpsDaily = async () => {
    try {
      // Fetch JWT token securely
      const { accessToken: { jwtToken } } = await Auth.currentSession();

      let url16 = `/api/explore/pixelData?country=${props.countryName}&pixelName=${pixelName}&dataset=ChirpsDaily`;

      if (dailyfishnetSwitchState) {
        setPixelChirpsDailyRainfall([]);
        setChirpsDailyRainfall([]);
        setPixelChirpsDailyLoading(true);

        if (pixelName !== "") {
          const response = await fetch(url16, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          setPixelChirpsDailyRainfall(data);
        } else {
          setPixelChirpsDailyEmpty(true);
        }
      }
    } catch (error) {
      setPixelChirpsDailyEmpty(true);
      setPixelChirpsDailyLoading(false);
      setPixelChirpsDailyRainfall([]);
      console.error("Error fetching Pixel Chirps Daily data:", error);
    } finally {
      setPixelChirpsDailyLoading(false);
    }
  };

  fetchPixelChirpsDaily();
}, [
  props.countryName,
  pixelName,
  props.chirps5kmDailyRainfall,
  dailyfishnetSwitchState,
]);

useEffect(() => {
  const fetchData = async () => {
    try {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();

      let url17 = `/api/explore/chirpsDailyForecast?country=${props.countryName}`;

      // Construct URL with state, district, level3, and level4 data
      if (props.stateName) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
            url17 += `&state=${props.stateName}`;
            break;
          case "Zambia":
            url17 += `&province=${props.stateName}`;
            break;
          case "Kenya":
            url17 += `&state=${props.stateName}`;
            break;
          case "Bangladesh":
            url17 += `&division=${props.stateName}`;
            break;
          default:
            break;
        }
      }

      if (props.districtName) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
            url17 += `&district=${props.districtName}`;
            break;
          case "Zambia":
            url17 += `&district=${props.districtName}`;
            break;
          case "Kenya":
            url17 += `&constituency=${props.districtName}`;
            break;
          case "Bangladesh":
            url17 += `&zila=${props.districtName}`;
            break;
          default:
            break;
        }
      }

      if (props.level3Data) {
        switch (props.countryName) {
          case "Tanzania":
          case "Sudan":
          case "Kenya":
            url17 += `&ward=${props.level3Data}`;
            break;
          case "Zambia":
            url17 += `&block=${props.level3Data}`;
          case "Bangladesh":
            url17 += `&upazila=${props.level3Data}`;
            break;
          default:
            break;
        }
      }

      if (props.level4Data) {
        switch (props.countryName) {
          case "Zambia":
            url17 += `&camp=${props.level4Data}`;
            break;
          case "Bangladesh":
            url17 += `&union=${props.level4Data}`;
            break;
          default:
            break;
        }
      }

      if (props.forecastedChirpsDailyRainfallGraph) {
        setforecastedChirpsDailyRainfallGraphloading(true);
        const response = await fetch(url17, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        });
        const data = await response.json();
        setforecastedChirpsDailyRainfallgraph(data);
        setforecastedChirpsDailyRainfallGraphloading(false);
      }
    } catch (error) {
      setforecastedChirpsDailyRainfallGraphloading(false);
      console.error("Error fetching data:", error);
    }
  };

  // Call fetchData
  fetchData();
}, [
  props.level2Data,
  props.level3Data,
  props.level4Data,
  props.countryName,
  props.stateName,
  props.forecastedChirpsDailyRainfallGraph,
]);

  const handleDatasetDownloadbutton = (dataset) => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      fetch(
        "/api/explore/exploreDatasetDownload?country=" +
        props.countryName +
        "&dataset=" +
        dataset +
        "&level1=" +
        stateName +
        "&level2=" +
        districtName +
        "&level3=" +
        level3Data +
        (level4Data ? "&level4=" + level4Data : "") +// Append level4 if it exists
        (pixelName ? "&pixel_name=" + pixelName : ""), // Append pixelName if it exists, 
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      )
        .then((response) => {
          // Check if the response is successful
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          // Check if the response contains the 'Content-Disposition' header
          const contentDisposition = response.headers.get(
            "Content-Disposition"
          );
          if (
            !contentDisposition ||
            !contentDisposition.includes("attachment")
          ) {
            throw new Error("Invalid or missing Content-Disposition header");
          }

          // Get the filename from the 'Content-Disposition' header
          const filename = `CDL_${dataset}_${formattedDate}.zip`;

          // Assume that 'data' is a zip file binary data
          return response.blob().then((data) => ({ data, filename }));
        })
        .then(({ data, filename }) => {
          // Create a link element and trigger the download
          const blob = new Blob([data], { type: "application/zip" });
          const a = document.createElement("a");
          a.href = window.URL.createObjectURL(blob);
          a.download = filename;
          a.click();

          // Revoke the object URL to free up resources
          window.URL.revokeObjectURL(a.href);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }

    genToken();
  };

  const formatDate = (d) =>
    new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
      .toISOString()
      .slice(0, 10);

  const getLayersArray = async (data, days, endDate, layerName) => {
    let res = [];
    let index = 1;
    let maxAttempts = 5; // Maximum number of attempts before giving up
    let baseDelayMs = 1000; // Initial delay in milliseconds
    const execute = async () => {
      let startDate = new Date();
      let temp = startDate.getDate();
      startDate.setDate(temp - days * index);
      startDate = formatDate(startDate); // Format startDate

      // Set loading state to true
      setndvilayeroverlayLoading(true);

      const geojsonData = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: data,
          },
        ],
      };

      let body = JSON.stringify(geojsonData);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body,
      };

      try {
        const response = await fetch(
          `/api/tiles/tile_generation?start_date=${startDate}&end_date=${endDate}&layer_name=${layerName}`,
          requestOptions
        );
        const result = await response.json();
        res = [...result];
        // Set loading state to false
        setndvilayeroverlayLoading(false);

        if (result.length === 0) {
          const delayMs = baseDelayMs * 2 ** index;
          //console.log(`Retry attempt ${index} after ${delayMs}ms`);
          await new Promise((resolve) => setTimeout(resolve, delayMs));
          index++;
          return execute(); // Retry the operation
        }
        return res; // Return the result if successful
      } catch (error) {
        console.error(error);
        // Set loading state to false
        setndvilayeroverlayLoading(false);

        if (index >= maxAttempts) {
          throw error; // Throw the error if maximum attempts reached
        }
        const delayMs = baseDelayMs * 2 ** index;
        //console.log(`Retry attempt ${index} after ${delayMs}ms`);
        await new Promise((resolve) => setTimeout(resolve, delayMs));
        index++;
        return execute(); // Retry the operation
      }
    };

    return execute();
  };

  const handleFishnetClick = (layerName = "") => {
    if (!fishnetSwitchState) {
      setFishnetLayer(null);
      map.removeLayer(
        map
          .getLayers()
          .getArray()
          .find((layer) => layer.get("name") === "highlightLayer")
      );
      const administrativeBoundaryGeometry = map
        .getLayers()
        .getArray()
        .find((layer) => layer.get("name") === "AdministrativeBoundary")
        .getSource()
        .getFeatures()[0]
        .getGeometry();
      const wktGeom = administrativeBoundaryGeometry.getExtent().join(",");
      setFishnetLayer({ wktGeom, layerName });
    } else {
      setFishnetLayer(null);
      map.removeLayer(
        map
          .getLayers()
          .getArray()
          .find((layer) => layer.get("name") === "highlightLayer")
      );
    }
    setFishnetSwitchState(!fishnetSwitchState);
  };

  const handledailyFishnetClick = (layerName = "") => {
    if (!dailyfishnetSwitchState) {
      const administrativeBoundaryGeometry = map
        .getLayers()
        .getArray()
        .find((layer) => layer.get("name") === "AdministrativeBoundary")
        .getSource()
        .getFeatures()[0]
        .getGeometry();
      const wktGeom = administrativeBoundaryGeometry.getExtent().join(",");
      setFishnetLayer({ wktGeom, layerName });
    } else {
      setFishnetLayer(null);
      map.removeLayer(
        map
          .getLayers()
          .getArray()
          .find((layer) => layer.get("name") === "highlightLayer")
      );
    }
    setdailyFishnetSwitchState(!dailyfishnetSwitchState);
  };

  const handlelandcoverDownloadbutton = () => {
    async function genToken() {
      fetch(
        "/api/explore/landCoverPercentDownload?country=" +
        props.countryName +
        "&level1=" +
        stateName +
        "&level2=" +
        districtName +
        "&level3=" +
        level3Data +
        (level4Data ? "&level4=" + level4Data : ""), // Append level4 if it exists
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: jwtToken,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          // Check if the response contains the 'Content-Disposition' header
          const contentDisposition = response.headers.get(
            "Content-Disposition"
          );
          if (
            !contentDisposition ||
            !contentDisposition.includes("attachment")
          ) {
            throw new Error("Invalid or missing Content-Disposition header");
          }

          // Get the filename from the 'Content-Disposition' header
          const filename = `CDL_landcover_${formattedDate}.zip`;

          // Assume that 'data' is a zip file binary data
          return response.blob().then((data) => ({ data, filename }));
        })
        .then(({ data, filename }) => {
          // Create a link element and trigger the download
          const blob = new Blob([data], { type: "application/zip" });
          const a = document.createElement("a");
          a.href = window.URL.createObjectURL(blob);
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(a.href);
        })
        .catch((error) => {
          // Handle errors
          console.error("Error:", error);
        });
    }

    genToken();
  };

  const expandDrawer = () => {
    setIsExpanded(!isExpanded);
  };

  const dataseticonStyle = {
    marginRight: "10px",
    height: "24px",
    width: "24px",
  };

  const showNDVILayers = (layersArray, layerPrefix) => {
    layersArray.forEach((layer, index) => {
      let source = new XYZ({
        url: layer,
        crossOrigin: "anonymous",
      });

      let layer1 = new TileLayer({
        source,
        name: layerPrefix + index,
        zIndex: 1,
      });
      map.addLayer(layer1);
    });
  };

  const handleModisNdviIconClick = async () => {
    dispatch(changeshowModisNDVIDot(!showModisNDVIDot));
    dispatch(changeModisNDVILayer(!modisNdviLayer));

    if (!showModisNDVIDot) {
      const administrativeBoundaryGeometry = map
        .getLayers()
        .getArray()
        .find((layer) => layer.get("name") === "AdministrativeBoundary")
        .getSource()
        .getFeatures()[0]
        .getGeometry();
      let geoJSONFormat = new GeoJSON();
      let geoJSON = geoJSONFormat.writeGeometry(administrativeBoundaryGeometry);
      geoJSON = JSON.parse(geoJSON);
      let endDate = new Date().toISOString().split("T")[0];
      const layersArray = await getLayersArray(
        geoJSON,
        5,
        endDate,
        "NDVI-Modis"
      );
      showNDVILayers(layersArray, "NDVIModis");
    } else {
      props.removeNDVILayers("NDVIModis");
    }
  };

  const handleSentinel2NdviIconClick = async () => {
    dispatch(changeshowSentinelNDVIDot(!showSentinel2NDVIDot));
    dispatch(changeSentinel2NDVILayer(!sentinel2NdviLayer));
    if (!showSentinel2NDVIDot) {
      const administrativeBoundaryGeometry = map
        .getLayers()
        .getArray()
        .find((layer) => layer.get("name") === "AdministrativeBoundary")
        .getSource()
        .getFeatures()[0]
        .getGeometry();
      let geoJSONFormat = new GeoJSON();
      let geoJSON = geoJSONFormat.writeGeometry(administrativeBoundaryGeometry);
      geoJSON = JSON.parse(geoJSON);
      let endDate = new Date().toISOString().split("T")[0];
      const layersArray = await getLayersArray(
        geoJSON,
        5,
        endDate,
        "NDVI-Sentinel"
      );
      showNDVILayers(layersArray, "NDVISentinel");
    } else {
      props.removeNDVILayers("NDVISentinel");
    }
  };

  const handleLandcoverIconClick = () => {
    setShowLandcoverDot(!showLandcoverDot);
    dispatch(changeLandcoverLayer(!landcoverLayer));
  };

  return (
    <div
      className={`map-drawer-container ${props.isDrawerOpen ? "shifted" : ""} ${isExpanded ? "expanded" : ""
        }`}
    >
      <div className="icon" onClick={props.toggleDrawer}>
        <img
          src={props.isDrawerOpen ? "pie-donut.png" : "pie-donutclose.png"}
          alt="location icon"
        />
      </div>
      <div
        className={`drawer-content ${props.isDrawerOpen ? "visible" : ""} ${isExpanded ? "expanded" : ""
          }`}
      >
        <div className="top-icons-container">
          <img
            src="expand-diagonal.png"
            alt="icon1"
            className="top-icon"
            onClick={expandDrawer}
          />
          <img
            src="chevron-2-right.png"
            onClick={() => props.setIsDrawerOpen(false)}
            alt="icon2"
            className="top-icon"
          />
        </div>
        <div className="maplayerspannel">
          <div className="exploretext">Visualize datasets</div>
          <div className="exploresecondarytext">
            {" "}
            Select a single dataset to layer on the Map.
          </div>
          <hr className="horizontal-rule" />
          <div className="datasetstext">Datasets</div>
          {props.chirps5kmDailyRainfall ? (
            <div>
              <div
                className="datasetgraph"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: 16,
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <img
                    src="raindrop.png"
                    alt="Raindrop"
                    style={dataseticonStyle}
                  />
                  {dailyfishnetSwitchState ? (
                    <div style={{ top: "10px", flexDirection: "column" }}>
                      CHIRPS 5km - Daily {pixelName}
                    </div>
                  ) : (
                    <div style={{ top: "10px", flexDirection: "column" }}>
                      CHIRPS 5km - Daily
                    </div>
                  )}
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="icononmap"
                    style={{
                      height: "32px",
                      width: "32px",
                      marginRight: "8px",
                      padding: "5px",
                    }}
                  >
                    <MdGridOn
                      style={{
                        height: "22px",
                        width: "20px",
                        marginRight: "8px",
                        color: "#818589",
                      }}
                      onClick={() => handledailyFishnetClick("5km_grid")}
                    />
                    {dailyfishnetSwitchState ? (
                      <div
                        className="green-dot"
                        style={{
                          position: "relative",
                          bottom: "19px",
                          left: "50%",
                          transform: "translate(50%, 50%)",
                          width: "6.667px",
                          height: "6.667px",
                          backgroundColor: "#19D919",
                          borderRadius: "50%",
                        }}
                      />
                    ) : (
                      <div
                        className="grey-dot"
                        style={{
                          position: "relative",
                          bottom: "19px",
                          left: "50%",
                          transform: "translate(50%, 50%)",
                          width: "6.667px",
                          height: "6.667px",
                          backgroundColor: "#CCCCCC", // Grey color
                          borderRadius: "50%",
                        }}
                      />
                    )}
                  </div>
                  <img
                    src="layers.png"
                    alt="Layer"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "8px",
                    }}
                  />
                  <img
                    src="download.png"
                    alt="Download"
                    style={{ height: "20px", width: "20px" }}
                    onClick={() =>
                      dailyfishnetSwitchState ? (handleDatasetDownloadbutton("Grid Chirps Daily Rainfall")) : (handleDatasetDownloadbutton("Chirps Daily Rainfall"))}
                  />
                </div>
              </div>

              {(chirpsdailyLoading && chirpsDailyRainfall.length === 0) || (pixelChirpsDailyLoading && pixelChirpsDailyRainfall.length === 0) ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                  }}
                >
                  <CircularProgress
                    style={{ color: "rgba(26, 26, 0, 0.16)" }}
                  />
                </div>
              ) : (
                ""
              )}
              {!dailyfishnetSwitchState && !chirpsdailyLoading && chirpsDailyRainfall.length > 0 ? (
                <div>
                  <LineChart
                    data={chirpsDailyRainfall}
                    state={props.stateName}
                    name={props.level3Data}
                  />
                </div>
              ) : (
                ""
              )}

              {!dailyfishnetSwitchState && chirpsDailyRainfall == null ? (
                <div
                  style={{
                    textAlign: "center",
                    height: "150px",
                    padding: "10%",
                    border: "1px solid grey",
                  }}
                >
                  <p style={{ color: "black" }}>No data available</p>
                </div>
              ) : (
                ""
              )}
              {dailyfishnetSwitchState &&
                pixelChirpsDailyRainfall.length > 0 ? (

                <div>
                  <LineChart
                    data={pixelChirpsDailyRainfall}
                    country={props.country}
                    pixelID={pixelID}
                    dataset="ChirpsDaily"
                  />
                </div>
              ) : (
                ""
              )}

              {dailyfishnetSwitchState && !pixelID ? (
                <div
                  style={{
                    textAlign: "center",
                    height: "150px",
                    padding: "10%",
                    border: "1px solid grey",
                  }}
                >
                  <p style={{ color: "black" }}>Select pixel from map</p>
                </div>
              ) : (
                ""
              )}
              {dailyfishnetSwitchState && pixelChirpsDailyEmpty == true && pixelChirpsDailyRainfall.length == 0 && !pixelChirpsDailyLoading &&
                pixelID ? (
                <div
                  style={{
                    textAlign: "center",
                    height: "150px",
                    padding: "10%",
                    border: "1px solid grey",
                  }}
                >
                  <p style={{ color: "black" }}>No pixel data available</p>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : null}

          {props.chirps5kmMonthlyRainfall ? (
            <div>
              <div
                className="datasetgraph"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: 16,
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <img
                    src="raindrop.png"
                    alt="Raindrop"
                    style={dataseticonStyle}
                  />
                  {fishnetSwitchState ? (
                    <div style={{ top: "10px", flexDirection: "column" }}>
                      CHIRPS 5km - Monthly {pixelName}
                    </div>
                  ) : (
                    <div style={{ top: "10px", flexDirection: "column" }}>
                      CHIRPS 5km - Monthly
                    </div>
                  )}
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="icononmap"
                    style={{
                      height: "32px",
                      width: "32px",
                      marginRight: "8px",
                      padding: "5px",
                    }}
                  >
                    <MdGridOn
                      style={{
                        height: "22px",
                        width: "20px",
                        marginRight: "8px",
                        color: "#818589",
                      }}
                      onClick={() => handleFishnetClick("5km_grid")}
                    />
                    {fishnetSwitchState ? (
                      <div
                        className="green-dot"
                        style={{
                          position: "relative",
                          bottom: "16px",
                          left: "64%",
                          transform: "translate(50%, 50%)",
                          width: "6.667px",
                          height: "6.667px",
                          backgroundColor: "#19D919",
                          borderRadius: "50%",
                        }}
                      />
                    ) : (
                      <div
                        className="grey-dot"
                        style={{
                          position: "relative",
                          bottom: "15px",
                          left: "65%",
                          transform: "translate(50%, 50%)",
                          width: "6.667px",
                          height: "6.667px",
                          backgroundColor: "#CCCCCC", // Grey color
                          borderRadius: "50%",
                        }}
                      />
                    )}
                  </div>
                  <img
                    src="layers.png"
                    alt="Layer"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "8px",
                    }}
                  />
                  <img
                    src="download.png"
                    alt="Download"
                    style={{ height: "20px", width: "20px" }}
                    onClick={() =>
                      fishnetSwitchState ? (handleDatasetDownloadbutton("Grid Chirps Monthly Rainfall")) : (handleDatasetDownloadbutton("Chirps Monthly Rainfall"))}
                  />
                </div>
              </div>
              {(chirpsmonthlyLoading && chirpsMonthlyRainfall.length == 0) || (pixelChirpsMonthlyLoading && pixelChirpsMonthlyRainfall.length === 0) ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                  }}
                >
                  <CircularProgress
                    style={{ color: "rgba(26, 26, 0, 0.16)" }}
                  />
                </div>
              ) : (
                ""
              )}
              {!fishnetSwitchState && chirpsMonthlyRainfall && chirpsMonthlyRainfall.length > 0 ? (
                <div>
                  <ColumnChart
                    data={chirpsMonthlyRainfall}
                    state={props.stateName}
                    name={props.level3Data}
                  />
                </div>
              ) : (
                ""
              )}
              {!fishnetSwitchState && !chirpsMonthlyRainfall.length > 0 && !chirpsdailyLoading ? (
                <div
                  style={{
                    textAlign: "center",
                    height: "150px",
                    padding: "10%",
                    border: "1px solid grey",
                  }}
                >
                  <p style={{ color: "black" }}>No data available</p>
                </div>
              ) : (
                ""
              )}
              {fishnetSwitchState && pixelChirpsMonthlyRainfall.length > 0 ? (

                <div>
                  <ColumnChart
                    data={pixelChirpsMonthlyRainfall}
                    country={props.country}
                    pixelID={pixelID}
                    dataset="Chirps Monthly Rainfall"
                  />
                </div>
              ) : (
                ""
              )}
              {fishnetSwitchState && !pixelID ? (
                <div
                  style={{
                    textAlign: "center",
                    height: "150px",
                    padding: "10%",
                    border: "1px solid grey",
                  }}
                >
                  <p style={{ color: "black" }}>Select pixel from map</p>
                </div>
              ) : (
                ""
              )}

              {fishnetSwitchState && pixelChirpsMonthlyEmpty == true && pixelChirpsMonthlyRainfall.length == 0 && !pixelChirpsMonthlyLoading &&
                pixelID ? (
                <div
                  style={{
                    textAlign: "center",
                    height: "150px",
                    padding: "10%",
                    border: "1px solid grey",
                  }}
                >
                  <p style={{ color: "black" }}>No pixel data available</p>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : null}
          {props.arc210kmDailyRainfall ? (
            <div>
              <div
                className="datasetgraph"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: 16,
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <img
                    src="raindrop.png"
                    alt="Raindrop"
                    style={dataseticonStyle}
                  />
                  <div style={{ top: "10px", flexDirection: "column" }}>
                    ARC2 10km Daily Rainfall
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="layers.png"
                    alt="Layer"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "8px",
                    }}
                  />
                  <img
                    src="download.png"
                    alt="Download"
                    style={{ height: "20px", width: "20px" }}
                    onClick={() => handleDatasetDownloadbutton("Arc2 Daily Rainfall")}
                  />
                </div>
              </div>
              {arc2dailyLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                  }}
                >
                  <CircularProgress
                    style={{ color: "rgba(26, 26, 0, 0.16)" }}
                  />
                </div>
              ) : arc2DailyRainfall ? (
                <div>
                  <LineChart
                    data={arc2DailyRainfall}
                    state={props.stateName}
                    name={props.level3Data}
                  />
                </div>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    height: "150px",
                    padding: "10%",
                    border: "1px solid grey",
                  }}
                >
                  <p style={{ color: "black" }}>No data available</p>
                </div>
              )}
            </div>
          ) : null}
          {props.tamsatDailyGraph ? (
            <div>
              <div
                className="datasetgraph"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: 16,
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <img
                    src="raindrop.png"
                    alt="Raindrop"
                    style={dataseticonStyle}
                  />
                  <div style={{ top: "10px", flexDirection: "column" }}>
                    TAMSAT Daily Rainfall
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/*<MdGridOn style={{ height: '22px', width: '20px', marginRight: '8px', color: '#818589' }} onClick={handleFishnetClick} />*/}
                  <img
                    src="layers.png"
                    alt="Layer"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "8px",
                    }}
                  />
                  <img
                    src="download.png"
                    alt="Download"
                    style={{ height: "20px", width: "20px" }}
                    onClick={() => handleDatasetDownloadbutton("Tamsat Daily Rainfall")}
                  />
                </div>
              </div>
              {tamsatdailyLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                  }}
                >
                  <CircularProgress
                    style={{ color: "rgba(26, 26, 0, 0.16)" }}
                  />
                </div>
              ) : tamsatDailyRainfall ? (
                <div>
                  <LineChart
                    data={tamsatDailyRainfall}
                    state={props.stateName}
                    name={props.level3Data}
                  />
                </div>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    height: "150px",
                    padding: "10%",
                    border: "1px solid grey",
                  }}
                >
                  <p style={{ color: "black" }}>No data available</p>
                </div>
              )}
            </div>
          ) : null}
          {props.tamsatMonthlyGraph ? (
            <div>
              <div
                className="datasetgraph"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: 16,
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <img
                    src="raindrop.png"
                    alt="Raindrop"
                    style={dataseticonStyle}
                  />
                  <div style={{ top: "10px", flexDirection: "column" }}>
                    TAMSAT Monthly Rainfall
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="layers.png"
                    alt="Layer"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "8px",
                    }}
                  />
                  <img
                    src="download.png"
                    alt="Download"
                    style={{ height: "20px", width: "20px" }}
                    onClick={() => handleDatasetDownloadbutton("Tamsat Monthly Rainfall")}
                  />
                </div>
              </div>
              {tamsatmonthlyLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                  }}
                >
                  <CircularProgress
                    style={{ color: "rgba(26, 26, 0, 0.16)" }}
                  />
                </div>
              ) : tamsatMonthlyRainfall ? (
                <div>
                  <ColumnChart
                    data={tamsatMonthlyRainfall}
                    state={props.stateName}
                    name={props.level3Data}
                  />
                </div>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    height: "150px",
                    padding: "10%",
                    border: "1px solid grey",
                  }}
                >
                  <p style={{ color: "black" }}>No data available</p>
                </div>
              )}
            </div>
          ) : null}
          {props.gsMapDaily ? (
            <div>
              <div
                className="datasetgraph"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: 16,
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <img
                    src="raindrop.png"
                    alt="Raindrop"
                    style={dataseticonStyle}
                  />
                  <div style={{ top: "10px", flexDirection: "column" }}>
                    GSMAP Daily Rainfall
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="layers.png"
                    alt="Layer"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "8px",
                    }}
                  />
                  <img
                    src="download.png"
                    alt="Download"
                    style={{ height: "20px", width: "20px" }}
                    onClick={() => handleDatasetDownloadbutton("Gsmap Daily Rainfall")}
                  />
                </div>
              </div>
              {gsmapdailyLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                  }}
                >
                  <CircularProgress
                    style={{ color: "rgba(26, 26, 0, 0.16)" }}
                  />
                </div>
              ) : gsMapDailygraph ? (
                <div>
                  <LineChart
                    data={gsMapDailygraph}
                    state={props.stateName}
                    name={props.level3Data}
                  />
                </div>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    height: "150px",
                    padding: "10%",
                    border: "1px solid grey",
                  }}
                >
                  <p style={{ color: "black" }}>No data available</p>
                </div>
              )}
            </div>
          ) : null}
          {props.gsMapMonthly ? (
            <div>
              <div
                className="datasetgraph"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: 16,
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <img
                    src="raindrop.png"
                    alt="Raindrop"
                    style={dataseticonStyle}
                  />
                  <div style={{ top: "10px", flexDirection: "column" }}>
                    GSMAP Monthly Rainfall
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="layers.png"
                    alt="Layer"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "8px",
                    }}
                  />
                  <img
                    src="download.png"
                    alt="Download"
                    style={{ height: "20px", width: "20px" }}
                    onClick={() => handleDatasetDownloadbutton("Gsmap Monthly Rainfall")}
                  />
                </div>
              </div>
              {gsmapmonthlyLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                  }}
                >
                  <CircularProgress
                    style={{ color: "rgba(26, 26, 0, 0.16)" }}
                  />
                </div>
              ) : gsMapMonthlygraph ? (
                <div>
                  <ColumnChart
                    data={gsMapMonthlygraph}
                    state={props.stateName}
                    name={props.level3Data}
                  />
                </div>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    height: "150px",
                    padding: "10%",
                    border: "1px solid grey",
                  }}
                >
                  <p style={{ color: "black" }}>No data available</p>
                </div>
              )}
            </div>
          ) : null}
          {props.era5DailyRainfallGraph ? (
            <div>
              <div
                className="datasetgraph"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: 16,
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <img
                    src="raindrop.png"
                    alt="Raindrop"
                    style={dataseticonStyle}
                  />
                  <div style={{ top: "10px", flexDirection: "column" }}>
                    ECMWF/ERA5 Daily Rainfall
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="layers.png"
                    alt="Layer"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "8px",
                    }}
                  />
                  <img
                    src="download.png"
                    alt="Download"
                    style={{ height: "20px", width: "20px" }}
                    onClick={() =>
                      handleDatasetDownloadbutton("Era5 Daily Rainfall")
                    }
                  />
                </div>
              </div>
              {era5dailyrainfallloading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                  }}
                >
                  <CircularProgress
                    style={{ color: "rgba(26, 26, 0, 0.16)" }}
                  />
                </div>
              ) : era5DailyRainfallgraph ? (
                <div>
                  <LineChart
                    data={era5DailyRainfallgraph}
                    state={props.stateName}
                    name={props.level3Data}
                  />
                </div>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    height: "150px",
                    padding: "10%",
                    border: "1px solid grey",
                  }}
                >
                  <p style={{ color: "black" }}>No data available</p>
                </div>
              )}
            </div>
          ) : null}
          {props.era5MonthlyRainfallGraph ? (
            <div>
              <div
                className="datasetgraph"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: 16,
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <img
                    src="raindrop.png"
                    alt="Raindrop"
                    style={dataseticonStyle}
                  />
                  <div style={{ top: "10px", flexDirection: "column" }}>
                    ECMWF/ERA5 Monthly Rainfall
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="layers.png"
                    alt="Layer"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "8px",
                    }}
                  />
                  <img
                    src="download.png"
                    alt="Download"
                    style={{ height: "20px", width: "20px" }}
                    onClick={() =>
                      handleDatasetDownloadbutton("Era5 Monthly Rainfall")
                    }
                  />
                </div>
              </div>
              {era5monthlyrainfallloading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                  }}
                >
                  <CircularProgress
                    style={{ color: "rgba(26, 26, 0, 0.16)" }}
                  />
                </div>
              ) : era5MonthlyRainfallgraph ? (
                <div>
                  <ColumnChart data={era5MonthlyRainfallgraph} />
                </div>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    height: "150px",
                    padding: "10%",
                    border: "1px solid grey",
                  }}
                >
                  <p style={{ color: "black" }}>No data available</p>
                </div>
              )}
            </div>
          ) : null}
           {props.forecastedChirpsDailyRainfallGraph ? (
            <div>
              <div
                className="datasetgraph"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: 16,
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <img
                    src="raindrop.png"
                    alt="Raindrop"
                    style={dataseticonStyle}
                  />
                  <div style={{ top: "10px", flexDirection: "column" }}>
                  Forecasted Chirps Daily Rainfall
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/*<MdGridOn style={{ height: '22px', width: '20px', marginRight: '8px', color: '#818589' }} onClick={handleFishnetClick} />*/}
                  <img
                    src="layers.png"
                    alt="Layer"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "8px",
                    }}
                  />
                  <img
                    src="download.png"
                    alt="Download"
                    style={{ height: "20px", width: "20px" }}
                    onClick={() => handleDatasetDownloadbutton("Forecasted Chirps Daily Rainfall")}
                  />
                </div>
              </div>
              {forecastedChirpsDailyRainfallGraphloading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                  }}
                >
                  <CircularProgress
                    style={{ color: "rgba(26, 26, 0, 0.16)" }}
                  />
                </div>
              ) : forecastedChirpsDailyRainfallgraph ? (
                <div>
                  <LineChart
                    data={forecastedChirpsDailyRainfallgraph}
                    state={props.stateName}
                    name={props.level3Data}
                  />
                </div>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    height: "150px",
                    padding: "10%",
                    border: "1px solid grey",
                  }}
                >
                  <p style={{ color: "black" }}>No data available</p>
                </div>
              )}
            </div>
          ) : null}
          {props.sentinal2NDVI ? (
            <div>
              <div
                className="datasetgraph"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: 16,
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <img
                    src="plant.png"
                    alt="Vegetation"
                    style={dataseticonStyle}
                  />
                  <div style={{ top: "10px", flexDirection: "column" }}>
                    SENTINEL-2 5 Day interval NDVI
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {ndvilayeroverlayLoading ? (
                    <>
                      {" "}
                      <CircularProgress
                        style={{
                          color: "rgba(26, 26, 0, 0.16)",
                          position: "relative",
                          height: "32px",
                          width: "32px",
                          marginRight: "8px",
                          padding: "5px",
                        }}
                      />
                    </>
                  ) : (
                    <div
                      className="icononmap"
                      style={{
                        position: "relative",
                        height: "32px",
                        width: "32px",
                        marginRight: "8px",
                        padding: "5px",
                      }}
                      onClick={handleSentinel2NdviIconClick}
                    >
                      <img
                        src="layers.png"
                        alt="Layer"
                        style={{ height: "20px", width: "20px" }}
                      />
                      {showSentinel2NDVIDot ? (
                        <div
                          className="green-dot"
                          style={{
                            position: "absolute",
                            bottom: "10px",
                            right: "10px",
                            transform: "translate(50%, 50%)",
                            width: "6.667px",
                            height: "6.667px",
                            backgroundColor: "#19D919",
                            borderRadius: "50%",
                          }}
                        />
                      ) : (
                        <div
                          className="grey-dot"
                          style={{
                            position: "absolute",
                            bottom: "10px",
                            right: "10px",
                            transform: "translate(50%, 50%)",
                            width: "6.667px",
                            height: "6.667px",
                            backgroundColor: "#CCCCCC",
                            borderRadius: "50%",
                          }}
                        />
                      )}
                    </div>
                  )}
                  <img
                    src="download.png"
                    alt="Download"
                    style={{ height: "20px", width: "20px" }}
                    onClick={() => handleDatasetDownloadbutton("Sentinel2 Ndvi")}
                  />
                </div>
              </div>
              {sentinal2Loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                  }}
                >
                  <CircularProgress
                    style={{ color: "rgba(26, 26, 0, 0.16)" }}
                  />
                </div>
              ) : sentinal2NDVIgraph.length > 0 ? (
                <div>
                  <NdviGraph
                    data={sentinal2NDVIgraph}
                    state={props.stateName}
                    name={props.level3Data}
                  />
                </div>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    height: "150px",
                    padding: "10%",
                    border: "1px solid grey",
                  }}
                >
                  <p style={{ color: "black" }}>No data available</p>
                </div>
              )}
            </div>
          ) : null}
          {props.modis5DayIntervalNDVIGraph ? (
            <div>
              <div
                className="datasetgraph"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: 16,
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <img
                    src="plant.png"
                    alt="Vegetation"
                    style={dataseticonStyle}
                  />
                  <div style={{ top: "10px", flexDirection: "column" }}>
                    MODIS 10 Day Interval NDVI
                  </div>
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  {ndvilayeroverlayLoading ? (
                    <>
                      {" "}
                      <CircularProgress
                        style={{
                          color: "rgba(26, 26, 0, 0.16)",
                          position: "relative",
                          height: "32px",
                          width: "32px",
                          marginRight: "8px",
                          padding: "5px",
                        }}
                      />
                    </>
                  ) : (
                    <div
                      className="icononmap"
                      style={{
                        position: "relative",
                        height: "32px",
                        width: "32px",
                        marginRight: "8px",
                        padding: "5px",
                      }}
                      onClick={handleModisNdviIconClick}
                    >
                      <img
                        src="layers.png"
                        alt="Layer"
                        style={{ height: "20px", width: "20px" }}
                      />
                      {showModisNDVIDot ? (
                        <div
                          className="green-dot"
                          style={{
                            position: "absolute",
                            bottom: "10px",
                            right: "10px",
                            transform: "translate(50%, 50%)",
                            width: "6.667px",
                            height: "6.667px",
                            backgroundColor: "#19D919",
                            borderRadius: "50%",
                          }}
                        />
                      ) : (
                        <div
                          className="grey-dot"
                          style={{
                            position: "absolute",
                            bottom: "10px",
                            right: "10px",
                            transform: "translate(50%, 50%)",
                            width: "6.667px",
                            height: "6.667px",
                            backgroundColor: "#CCCCCC",
                            borderRadius: "50%",
                          }}
                        />
                      )}
                    </div>
                  )}

                  <img
                    src="download.png"
                    alt="Download"
                    style={{ height: "20px", width: "20px" }}
                    onClick={() => handleDatasetDownloadbutton("Modis Eviirs Ndvi")}
                  />
                </div>
              </div>
              {modisloading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                  }}
                >
                  <CircularProgress
                    style={{ color: "rgba(26, 26, 0, 0.16)" }}
                  />
                </div>
              ) : modisNDVI.length > 0 ? (
                <div>
                  <NdviGraph
                    data={modisNDVI}
                    state={props.stateName}
                    name={props.level3Data}
                  />
                </div>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    height: "150px",
                    padding: "10%",
                    border: "1px solid grey",
                  }}
                >
                  <p style={{ color: "black" }}>No data available</p>
                </div>
              )}
            </div>
          ) : null}

          {props.landcover ? (
            <div>
              <div
                className="datasetgraph"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: 16,
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <img
                    src="mountain.png"
                    alt="Raindrop"
                    style={dataseticonStyle}
                  />
                  <div style={{ top: "10px", flexDirection: "column" }}>
                    Landcover
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="icononmap"
                    style={{
                      position: "relative",
                      height: "32px",
                      width: "32px",
                      marginRight: "8px",
                      padding: "5px",
                    }}
                    onClick={handleLandcoverIconClick}
                  >
                    <img
                      src="layers.png"
                      alt="Layer"
                      style={{ height: "20px", width: "20px" }}
                    />
                    {showLandcoverDot ? (
                      <div
                        className="green-dot"
                        style={{
                          position: "absolute",
                          bottom: "10px",
                          right: "10px",
                          transform: "translate(50%, 50%)",
                          width: "6.667px",
                          height: "6.667px",
                          backgroundColor: "#19D919",
                          borderRadius: "50%",
                        }}
                      />
                    ) : (
                      <div
                        className="grey-dot"
                        style={{
                          position: "absolute",
                          bottom: "10px",
                          right: "10px",
                          transform: "translate(50%, 50%)",
                          width: "6.667px",
                          height: "6.667px",
                          backgroundColor: "#CCCCCC",
                          borderRadius: "50%",
                        }}
                      />
                    )}
                  </div>
                  <img
                    src="download.png"
                    alt="Download"
                    style={{ height: "20px", width: "20px" }}
                    onClick={() => handleDatasetDownloadbutton("landcover")}
                  />
                </div>
              </div>
              {landcoverloading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                  }}
                >
                  <CircularProgress
                    style={{ color: "rgba(26, 26, 0, 0.16)" }}
                  />
                </div>
              ) : landcovergraph ? (
                <div>
                  <PieChart
                    data={landcovergraph}
                    state={props.stateName}
                    name={props.level3Data}
                  />
                </div>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    height: "150px",
                    padding: "10%",
                    border: "1px solid grey",
                  }}
                >
                  <p style={{ color: "black" }}>No data available</p>
                </div>
              )}
            </div>
          ) : null}
          {props.era5DailyTemperatureGraph ? (
            <div>
              <div
                className="datasetgraph"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: 16,
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <img
                    src="thermometer.png"
                    alt="Raindrop"
                    style={dataseticonStyle}
                  />
                  <div style={{ top: "10px", flexDirection: "column" }}>
                    ECMWF/ERA5 Daily Temperature
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="layers.png"
                    alt="Layer"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "8px",
                    }}
                  />
                  <img
                    src="download.png"
                    alt="Download"
                    style={{ height: "20px", width: "20px" }}
                    onClick={() =>
                      handleDatasetDownloadbutton("Era5 Daily Temperature")
                    }
                  />
                </div>
              </div>
              {era5dailytempoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                  }}
                >
                  <CircularProgress
                    style={{ color: "rgba(26, 26, 0, 0.16)" }}
                  />
                </div>
              ) : era5DailyTemperaturegraph ? (
                <div>
                  <LineChartTemp data={era5DailyTemperaturegraph} />
                </div>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    height: "150px",
                    padding: "10%",
                    border: "1px solid grey",
                  }}
                >
                  <p style={{ color: "black" }}>No data available</p>
                </div>
              )}
            </div>
          ) : null}
          {props.era5MonthlyTemperatureGraph ? (
            <div>
              <div
                className="datasetgraph"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: 16,
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <img
                    src="thermometer.png"
                    alt="Raindrop"
                    style={dataseticonStyle}
                  />
                  <div style={{ top: "10px", flexDirection: "column" }}>
                    ECMWF/ERA5 Monthly Temperature
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="layers.png"
                    alt="Layer"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "8px",
                    }}
                  />
                  <img
                    src="download.png"
                    alt="Download"
                    style={{ height: "20px", width: "20px" }}
                    onClick={() =>
                      handleDatasetDownloadbutton("Era5 Monthly Temperature")
                    }
                  />
                </div>
              </div>
              {era5monthlytempoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                  }}
                >
                  <CircularProgress
                    style={{ color: "rgba(26, 26, 0, 0.16)" }}
                  />
                </div>
              ) : era5MonthlyTemperaturegraph ? (
                <div>
                  <ColumnChartTemp
                    data={era5MonthlyTemperaturegraph}
                    state={props.stateName}
                    name={props.level3Data}
                  />
                </div>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    height: "150px",
                    padding: "10%",
                    border: "1px solid grey",
                  }}
                >
                  <p style={{ color: "black" }}>No data available</p>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MapDrawer;
