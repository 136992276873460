import React, { useState, useEffect } from "react";
import "../../styles.css";
import { Auth } from "aws-amplify";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import { IoSearchOutline, IoChevronDown } from "react-icons/io5";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FaCaretDown } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function Datasetsfordownload(props) {
  const [value, setValue] = React.useState("one");
  const [DatasetOptions, setDatasetOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  let filteredOptions = [];
  useEffect(() => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();

      fetch("/api/downloads/datasets?country=" + props.countryname, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setDatasetOptions(data);
        });
    }

    genToken();
  }, [props.countryname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleSelectAll = () => {
    if (!selectAll) {
      const allOptions = DatasetOptions[value]; // Get all options based on the current tab value
      setSelectedItems(allOptions);
      props.handleDatasetSelection(allOptions);
    } else {
      setSelectedItems([]);
      props.handleDatasetSelection([]);
    }
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = (event, option) => {
    if (event.target.checked) {
      if (selectedItems.length >= 1) {
        // Limit to 1 dataset
        toast.error(
          <div>
            <div
              style={{
                fontSize: "14px",
                marginBottom: "5px",
                fontWeight: "bold",
              }}
            >
              Maximum Datasets Selected
            </div>

            <div
              style={{ marginTop: "5px", fontSize: "12px", fontWeight: 400 }}
            >
              Up to 1 dataset can be displayed for any selected location
            </div>
          </div>
        ); // Show toast message
        return;
      }

      setSelectedItems((prevSelectedItems) => {
        const updatedItems = [...prevSelectedItems, option];
        props.handleDatasetSelection(updatedItems); // Pass updatedItems to props
        return updatedItems;
      });
    } else {
      setSelectedItems((prevSelectedItems) => {
        const updatedItems = prevSelectedItems.filter(
          (item) => item !== option
        );
        props.handleDatasetSelection(updatedItems); // Pass updatedItems to props
        return updatedItems;
      });
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSelectAllFromMenu = () => {
    setSelectedItems(filteredOptions);
    props.handleDatasetSelection(filteredOptions);
    setSelectAll(true);
    handleMenuClose();
  };

  const handleClearAllFromMenu = () => {
    setSelectedItems([]);
    props.handleDatasetSelection([]);
    setSelectAll(false);
    handleMenuClose();
  };

  const renderCheckboxOptions = () => {
    switch (value) {
      case "one":
        filteredOptions =
          DatasetOptions.rainfall &&
          DatasetOptions.rainfall.filter((option) =>
            option.toLowerCase().includes(searchTerm.toLowerCase())
          );
        break;
      case "two":
        filteredOptions =
          DatasetOptions.ndvi &&
          DatasetOptions.temperature.filter((option) =>
            option.toLowerCase().includes(searchTerm.toLowerCase())
          );
        break;
      case "three":
        filteredOptions =
          DatasetOptions.ndvi &&
          DatasetOptions.ndvi.filter((option) =>
            option.toLowerCase().includes(searchTerm.toLowerCase())
          );
        break;
      default:
        break;
    }

    return (
      <>
        {filteredOptions
          ? filteredOptions.map((option, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={selectedItems.includes(option)}
                    onChange={(event) => handleCheckboxChange(event, option)}
                  />
                }
                label={option}
                sx={{ marginLeft: 0, display: "flex", flexDirection: "row" }}
              />
            ))
          : null}
      </>
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          paddingRight: "20px",
          float: "right",
          marginLeft: "280px",
        }}
      >
        <div style={{ position: "relative", marginRight: "5px" }}>
          <IoSearchOutline
            style={{
              position: "absolute",
              left: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              color: "#777",
            }}
          />
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Search Dataset"
            style={{
              paddingLeft: "30px",
              width: "298px",
              height: "36px",
              fontSize: "14px",
              borderRadius: "8px",
              border: "1px solid rgba(26, 26, 0, 0.16)",
            }}
          />
        </div>
        <div
          style={{
            border: "1px solid rgba(26, 26, 0, 0.16)",
            gap: "4px",
            borderRadius: "8px",
            height: "36px",
            width: "60px",
            padding: "8px",
            display: "flex",
          }}
        >
          <IconButton
            style={{ width: "20px", height: "20px" }}
            onClick={handleSelectAll}
          >
            <Checkbox
              checked={selectAll}
              onClick={handleSelectAll}
              sx={{
                color: "rgba(26, 26, 0, 0.16)",
                "&.Mui-checked": {
                  color: "#F76808",
                },
              }}
            />
          </IconButton>
          <IconButton
            style={{ width: "20px", height: "20px", padding: 0 }}
            onClick={handleMenuOpen}
          >
            <FaCaretDown />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleSelectAllFromMenu}>Select All</MenuItem>
            <MenuItem onClick={handleClearAllFromMenu}>Clear All</MenuItem>
          </Menu>
        </div>
      </Box>

      <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
        sx={{
          "& .MuiTabs-indicator": {
            // Customize the color of the indicator (underline)
            backgroundColor: value === "one" ? "#F76808" : "inherit",
          },
          "& .MuiTab-root.Mui-selected": {
            // Customize the color of the selected tab label
            color: "#F76808",
          },
        }}
      >
        <Tab
          value="one"
          label="Rainfall"
          sx={{ textTransform: "capitalize" }}
        />
        <Tab
          value="two"
          label="Temperature"
          sx={{ textTransform: "capitalize" }}
        />
        <Tab
          value="three"
          label="Vegetation"
          sx={{ textTransform: "capitalize" }}
        />
      </Tabs>
      <Box>{renderCheckboxOptions()}</Box>
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{
          width: "400px",
          padding: "16px",
          textAlign: "initial",
          color: "#21201C",
        }}
      />
    </Box>
  );
}
