import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
require("highcharts/modules/exporting")(Highcharts);

export const NdviGraph = (props) => {
  let final = [];
  console.log(props,props.data)

  if (props && !props.data.error) {
    final = props.data
      .filter(item => item.date && item.ndvi !== null && item.ndvi !== undefined) // Filter out null and undefined values
      .map(item => [new Date(item.date).getTime(), item.ndvi]); // Convert date to timestamp and format the data
  }

  const options = {
    chart: {
      alignTicks: false,
    },
    rangeSelector: {
      inputEnabled: false,
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      opposite: false,
    },
    exporting: {
      enabled: true,
      filename: props.state + props.name + "_Ndvi",
    },
    tooltip: {
      formatter: function () {
        return '<b>Date: </b>' + Highcharts.dateFormat('%Y-%m-%d', this.x) + '<br/><b>NDVI: </b>' + Highcharts.numberFormat(this.y, 2);
      },
    },
    series: [
      {
        type: "area",
        name: "Ndvi",
        data: final,
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          opacity: 0.2,
          stops: [
            [0, '#01619E'], // start color
            [1, 'rgba(1, 97, 158, 0.12)'] // end color
          ],
        },
      },
    ],
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType={"stockChart"}
      options={options}
    />
  );
};
