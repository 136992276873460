import React, { useState, useEffect } from "react";
import "../../styles.css";
import { Auth } from "aws-amplify";
import { ColumnChart } from "./ColumnChart";
import { LineChart } from "./LineChart";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
const PixelMapDrawer = (props) => {
  const stateName = useSelector((state) => state.stateName);
  const districtName = useSelector((state) => state.districtName);
  const level4Data = useSelector((state) => state.level4Data);
  const level3Data = useSelector((state) => state.level3Data);
  const [isExpanded, setIsExpanded] = useState(false);
  const [chirpsMonthlyRainfall, setChirpsMonthlyRainfall] = useState([]);
  const [chirpsDailyRainfall, setChirpsDailyRainfall] = useState([]);
  const [chirpsDailyLoading, setChirpsDailyLoading] = useState(false);
  const [chirpsMonthlyLoading, setChirpsMonthlyLoading] = useState(false);
  const [pixelChirpsMonthlyLoading, setPixelChirpsMonthlyLoading] = useState(false);
  const [pixelChirpsDailyLoading, setPixelChirpsDailyLoading] = useState(false);
  const pixelName = useSelector((state) => state.pixelName)
  const pixel_id = useSelector((state) => state.pixel_id);
  const layerName = useSelector((state) => state.layerName);
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().slice(0, 10);


  useEffect(() => {
    async function fetchData(layerName) {
      setChirpsDailyLoading(true);
      setPixelChirpsDailyLoading(true);
      setChirpsDailyRainfall([])

      if (pixelName !== "") {
        try {
          const tokenResponse = await Auth.currentSession(); 
          const jwtToken = tokenResponse.accessToken.jwtToken;

          const response = await fetch(`/api/explore/pixelData?country=Bangladesh&pixelName=${pixelName}&dataset=ChirpsDaily`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();

          return data;
        } catch (error) {
          console.error('Error fetching data:', error);
          return null;
        }
      }
      else {
        return null;
      }
    }


    async function fetchDataAndUpdate(layerName, setDataFunction) {
      const data = await fetchData(layerName);
      setDataFunction(data);
      setChirpsDailyLoading(false);
      setPixelChirpsDailyLoading(false);
    }

    fetchDataAndUpdate("Grid Chirps Daily Rainfall", setChirpsDailyRainfall);
  }, [pixel_id, layerName, props.explorepixelDataset, props.chirps5kmDailyRainfall]);


  useEffect(() => {
    async function fetchData(layerName) {
      setChirpsMonthlyLoading(true);
      setChirpsMonthlyRainfall([])

      if (pixelName !== "") {
        try {
          const {
            accessToken: { jwtToken },
          } = await Auth.currentSession();
      
          const response = await fetch(`/api/explore/pixelData?country=Bangladesh&pixelName=${pixelName}&dataset=ChirpsMonthly`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();

          return data;
        } catch (error) {
          console.error('Error fetching data:', error);
          return null;
        }
      }
      else {
        return null;
      }
    }


    async function fetchDataAndUpdate(layerName, setDataFunction) {
      const data = await fetchData(layerName);
      setDataFunction(data);
      setChirpsMonthlyLoading(false);
      setPixelChirpsMonthlyLoading(false);
    }

    fetchDataAndUpdate("Grid Chirps Monthly rainfall", setChirpsMonthlyRainfall);
  }, [pixel_id, layerName, props.explorepixelDataset, props.chirps5kmMonthlyRainfall]);

  const handleDatasetDownloadbutton = (dataset) => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
  
      fetch(
        "/api/explore/exploreDatasetDownload?country=" +
        props.countryName +
        "&dataset=" +
        dataset +
        "&level1=" +
        stateName +
        "&level2=" +
        districtName +
        "&level3=" +
        level3Data +
        (level4Data ? "&level4=" + level4Data : ""), // Append level4 if it exists
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        })

        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          // Check if the response contains the 'Content-Disposition' header
          const contentDisposition = response.headers.get('Content-Disposition');
          if (!contentDisposition || !contentDisposition.includes('attachment')) {
            throw new Error('Invalid or missing Content-Disposition header');
          }

          // Get the filename from the 'Content-Disposition' header
          const filename = `CDL_${dataset}_${formattedDate}.zip`;

          // Assume that 'data' is a zip file binary data
          return response.blob().then((data) => ({ data, filename }));
        })
        .then(({ data, filename }) => {
          // Create a link element and trigger the download
          const blob = new Blob([data], { type: 'application/zip' });
          const a = document.createElement('a');
          a.href = window.URL.createObjectURL(blob);
          a.download = filename;
          a.click();

          // Revoke the object URL to free up resources
          window.URL.revokeObjectURL(a.href);

          // Set loading state if needed
        })
        .catch((error) => {
          // Handle errors
          console.error('Error:', error);
        });
    }

    genToken();
  };

  const expandDrawer = () => {
    setIsExpanded(!isExpanded);
  };

  const dataseticonStyle = {
    marginRight: '10px',
    height: '24px',
    width: '24px',
  };

  return (
    <div className={`map-drawer-container ${props.isDrawerOpen ? 'shifted' : ''} ${isExpanded ? 'expanded' : ''}`} >
      <div className="icon" onClick={props.toggleDrawer}>
        <img src={props.isDrawerOpen ? "pie-donut.png" : "pie-donutclose.png"} alt="location icon" />
      </div>
      <div className={`drawer-content ${props.isDrawerOpen ? 'visible' : ''} ${isExpanded ? 'expanded' : ''}`} >
        <div className="top-icons-container">
          <img src="expand-diagonal.png" alt="icon1" className="top-icon" onClick={expandDrawer} />
          <img src="chevron-2-right.png" onClick={() => props.setIsDrawerOpen(false)} alt="icon2" className="top-icon" />
        </div>
        <div className='maplayerspannel'>
          <div className="exploretext">Visualize  datasets</div>
          <div className="exploresecondarytext"> Select a single dataset to layer on the Map.</div>
          <hr className="horizontal-rule" />
          <div className='datasetstext'>Datasets</div>
          {
            props.chirps5kmDailyRainfall ?
              <div>
                <div className='datasetgraph' style={{ display: 'flex', alignItems: 'center', padding: 16, justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <img src="raindrop.png" alt="Raindrop" style={dataseticonStyle} />
                    <div style={{ top: '10px', flexDirection: 'column' }}>CHIRPS 5km - Daily</div>

                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src="layers.png" alt="Layer" style={{ height: '20px', width: '20px', marginRight: '8px' }} />
                    <img
                      src="download.png"
                      alt="Download"
                      style={{ height: '20px', width: '20px' }}
                      onClick={() => handleDatasetDownloadbutton("chirpsDaily")}
                    />
                  </div>
                </div>
                {chirpsDailyLoading && chirpsDailyRainfall.length === 0 || pixelChirpsDailyLoading ?
                  (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress style={{ color: 'rgba(26, 26, 0, 0.16)' }} />
                  </div>) : ('')
                }
                {!chirpsDailyLoading && chirpsDailyRainfall.length > 0 ? (<div>
                  <LineChart
                    data={chirpsDailyRainfall}
                    state={props.stateName}
                    name={props.level3Data}
                  />
                </div>) : ('')}
                {!chirpsDailyRainfall ? (<div style={{ textAlign: "center", height: '150px', padding: '10%', border: '1px solid grey' }}>
                  <p style={{ color: "black" }}>No data available</p>
                </div>) : ('')}

              </div>
              :
              null
          }

          {
            props.chirps5kmMonthlyRainfall ?

              <div>
                <div className='datasetgraph' style={{ display: 'flex', alignItems: 'center', padding: 16, justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                    <img src="raindrop.png" alt="Raindrop" style={dataseticonStyle} />
                    <div style={{ top: '10px', flexDirection: 'column' }}>CHIRPS 5km - Monthly</div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src="layers.png" alt="Layer" style={{ height: '20px', width: '20px', marginRight: '8px' }} />
                    <img
                      src="download.png"
                      alt="Download"
                      style={{ height: '20px', width: '20px' }}
                      onClick={() => handleDatasetDownloadbutton("Chirps Monthly Rainfall")}
                    />
                  </div>
                </div>
                {chirpsMonthlyLoading && chirpsMonthlyRainfall.length === 0 || pixelChirpsMonthlyLoading ? (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                  <CircularProgress style={{ color: 'rgba(26, 26, 0, 0.16)' }} />
                </div>) : ('')
                }
                {!chirpsMonthlyLoading && chirpsMonthlyRainfall.length > 0 ? (<div >
                  <ColumnChart
                    data={chirpsMonthlyRainfall}
                    state={props.stateName}
                    name={props.level3Data}
                  />
                </div>) : (
                  ''
                )}
                {!chirpsMonthlyRainfall ? (<div style={{ textAlign: "center", height: '150px', padding: '10%', border: '1px solid grey' }}>
                  <p style={{ color: "black" }}>No data available</p>
                </div>) : ('')}
              </div>
              :
              null}

        </div>
      </div>
    </div>
  );
};

export default PixelMapDrawer;
