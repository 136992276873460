import React, { useState, useEffect } from "react";
import "../../styles.css";
import { Auth } from "aws-amplify";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FaCaretDown } from "react-icons/fa";
import Checkbox from "@mui/material/Checkbox";

const Timeperiodselect = (props) => {
  const [getYears, setgetYears] = useState([]);
  const [yearSearch, setYearSearch] = useState("");
  const [getMonth, setgetMonth] = useState([]);
  const [monthSearch, setMonthSearch] = useState("");
  const [selectAllYear, setSelectAllYear] = useState(false);
  const [selectAllMonth, setSelectAllMonth] = useState(false);
  const [anchorElYear, setAnchorElYear] = useState(null);
  const [anchorElMonth, setAnchorElMonth] = useState(null);

  useEffect(() => {
    async function genToken() {
      const {
          accessToken: { jwtToken },
      } = await Auth.currentSession();

      fetch("/api/downloads/getYears", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setgetYears(data);
        });
      fetch("/api/downloads/getMonths", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // Authorization: jwtToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setgetMonth(data);
        });
    }

    genToken();
  }, []);

  const handleSelectAllYear = () => {
    setSelectAllYear(!selectAllYear);
    if (!selectAllYear) {
      props.setSelectedYears(getYears);
    } else {
      props.setSelectedYears([]);
    }
  };

  const handleSelectAllMonth = () => {
    setSelectAllMonth(!selectAllMonth);
    if (!selectAllMonth) {
      props.setSelectedMonths(getMonth);
    } else {
      props.setSelectedMonths([]);
    }
  };

  const handleMenuOpenYear = (event) => {
    setAnchorElYear(event.currentTarget);
  };

  const handleMenuOpenMonth = (event) => {
    setAnchorElMonth(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorElYear(null);
    setAnchorElMonth(null);
  };

  const handleSelectAllYearFromMenu = () => {
    props.setSelectedYears(getYears);
    setSelectAllYear(true);
    handleMenuClose();
  };

  const handleSelectAllMonthFromMenu = () => {
    props.setSelectedMonths(getMonth);
    setSelectAllMonth(true);
    handleMenuClose();
  };
  const handleClearAllYearFromMenu = () => {
    props.setSelectedYears([]);
    setSelectAllYear(false);
    handleMenuClose();
  };

  const handleClearAllMonthFromMenu = () => {
    props.setSelectedMonths([]);
    setSelectAllMonth(false);
    handleMenuClose();
  };
  // Filter years based on search input
  const filteredYears = getYears.filter((year) =>
    year.toString().toLowerCase().includes(yearSearch.toLowerCase())
  );
  const filteredMonths = getMonth.filter((month) =>
    month.toLowerCase().includes(monthSearch.toLowerCase())
  );

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div className="searchbar1">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ position: "relative", marginRight: "4%" }}>
            <input
              type="text"
              value={yearSearch}
              onChange={(e) => setYearSearch(e.target.value)}
              placeholder="Enter Year"
              style={{
                padding: "0px 12px",
                width: "370px",
                height: "36px",
                fontSize: "14px",
                borderRadius: "8px",
                border: "1px solid rgba(26, 26, 0, 0.16)",
              }}
            />
          </div>
          <div
            style={{
              border: "1px solid rgba(26, 26, 0, 0.16)",
              gap: "4px",
              borderRadius: "8px",
              height: "36px",
              width: "60px",
              padding: "8px",
              display: "flex",
              alignContent: "end",
            }}
          >
            <IconButton
              style={{ width: "20px", height: "20px" }}
              onClick={handleSelectAllYear}
            >
              <Checkbox
                checked={selectAllYear}
                sx={{
                  color: "rgba(26, 26, 0, 0.16)",
                  "&.Mui-checked": {
                    color: "#F76808",
                  },
                }}
              />
            </IconButton>
            <IconButton
              style={{ width: "20px", height: "20px", padding: 0 }}
              onClick={handleMenuOpenYear}
            >
              <FaCaretDown />
            </IconButton>
            <Menu
              anchorEl={anchorElYear}
              open={Boolean(anchorElYear)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleSelectAllYearFromMenu}>
                Select All
              </MenuItem>
              <MenuItem onClick={handleClearAllYearFromMenu}>
                Clear All
              </MenuItem>
            </Menu>
          </div>
        </div>
        <div
          className="list"
          style={{
            textAlign: "left",
            padding: "10px",
            width: "32vw",
            height: "176px",
          }}
        >
          {filteredYears.length > 0 ? (
            filteredYears.map((year, index) => (
              <div key={index}>
                <input
                  type="checkbox"
                  id={year}
                  checked={props.selectedYears.includes(year)}
                  onChange={() => {
                    if (props.selectedYears.includes(year)) {
                      props.setSelectedYears(
                        props.selectedYears.filter((y) => y !== year)
                      );
                    } else {
                      props.setSelectedYears([...props.selectedYears, year]);
                    }
                  }}
                />
                <label htmlFor={year}>{year}</label>
              </div>
            ))
          ) : (
            <div>No results found</div>
          )}
        </div>
      </div>
      <div className="searchbar2">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ position: "relative", marginRight: "4%" }}>
            <input
              type="text"
              value={monthSearch}
              onChange={(e) => setMonthSearch(e.target.value)}
              placeholder="Enter Month"
              style={{
                padding: "0px 12px",
                width: "370px",
                height: "36px",
                fontSize: "14px",
                borderRadius: "8px",
                border: "1px solid rgba(26, 26, 0, 0.16)",
              }}
            />
          </div>
          <div
            style={{
              border: "1px solid rgba(26, 26, 0, 0.16)",
              gap: "4px",
              borderRadius: "8px",
              height: "36px",
              width: "60px",
              padding: "8px",
              display: "flex",
              alignContent: "end",
            }}
          >
            <IconButton
              style={{ width: "20px", height: "20px" }}
              onClick={handleSelectAllMonth}
            >
              <Checkbox
                checked={selectAllMonth}
                sx={{
                  color: "rgba(26, 26, 0, 0.16)",
                  "&.Mui-checked": {
                    color: "#F76808",
                  },
                }}
              />
            </IconButton>
            <IconButton
              style={{ width: "20px", height: "20px", padding: 0 }}
              onClick={handleMenuOpenMonth}
            >
              <FaCaretDown />
            </IconButton>
            <Menu
              anchorEl={anchorElMonth}
              open={Boolean(anchorElMonth)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleSelectAllMonthFromMenu}>
                Select All
              </MenuItem>
              <MenuItem onClick={handleClearAllMonthFromMenu}>
                Clear All
              </MenuItem>
            </Menu>
          </div>
        </div>
        <div
          className="list"
          style={{
            textAlign: "left",
            padding: "10px",
            width: "32vw",
            height: "176px",
          }}
        >
          {filteredMonths.length > 0 ? (
            filteredMonths.map((month, index) => (
              <div key={index}>
                <input
                  type="checkbox"
                  id={month}
                  checked={props.selectedMonths.includes(month)}
                  onChange={() => {
                    if (props.selectedMonths.includes(month)) {
                      props.setSelectedMonths(
                        props.selectedMonths.filter((m) => m !== month)
                      );
                    } else {
                      props.setSelectedMonths([...props.selectedMonths, month]);
                    }
                  }}
                />
                <label htmlFor={month}>{month}</label>
              </div>
            ))
          ) : (
            <div>No results found</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Timeperiodselect;
