import React, { useState, useEffect } from "react";
import "../../styles.css";
import AdministrativeBoundary from "./AdministrativeBoundary";
import MapDrawer from "./MapDrawer";
import { useDispatch, useSelector } from "react-redux";
//import { OlMap } from "./OlMap";
//import { OlMap2 } from "./GeeMap";
// import ZoomableMap from "./ZoomableMap";
// import { changeZoom } from "../../redux/action";
import MapView from "./Mapping/MapView";
import EnterCoordinates from "./EnterCoordinates";
import SearchLocation from "./SearchLocation";
import DrawAShape from "./DrawAShape";
import UploadAShape from "./UploadAShape";
import SearchByPixel from "./SearchByPixel";
import PixelMapDrawer from "./PixelMapDrawer";
import { useMap } from "../../hooks/useMap";

const Explore = () => {
  const { fishnetLayer, setFishnetLayer, map } = useMap();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [countryName, setCountryName] = useState("");
  const stateName = useSelector((state) => state.stateName);
  const level3Data = useSelector((state) => state.level3Data);
  const districtName = useSelector((state) => state.districtName);
  const level4Data = useSelector((state) => state.level4Data);
  const [tamsatMonthlyGraph, setTamsatMonthlyGraph] = useState(false);
  const [tamsatDailyGraph, setTamsatDailyGraph] = useState(false);
  const [modis5DayIntervalNDVIGraph, setModis5DayIntervalNDVIGraph] =
    useState(false);
  const [chirps5kmDailyRainfall, setChirps5kmDailyRainfall] = useState(false);
  const [chirps5kmMonthlyRainfall, setChirps5kmMonthlyRainfall] =
    useState(false);
  const [arc210kmDailyRainfall, setarc210kmDailyRainfall] = useState(false);
  const [landcover, setlandcover] = useState(false);
  const [era5MonthlyRainfallGraph, setera5MonthlyRainfallGraph] =
    useState(false);
  const [era5DailyRainfallGraph, setera5DailyRainfallGraph] = useState(false);
  const [forecastedChirpsDailyRainfallGraph, setforecastedChirpsDailyRainfallGraph] = useState(false);
  const [era5MonthlyTemperatureGraph, setera5MonthlyTemperatureGraph] =
    useState(false);
  const [era5DailyTemperatureGraph, setera5DailyTemperatureGraph] =
    useState(false);
  const [gsMapDaily, setgsMapDaily] = useState(false);
  const [gsMapMonthly, setgsMapMonthly] = useState(false);
  const [sentinal2NDVI, setsentinal2NDVI] = useState(false);
  const [showAdministrativeBoundary, setShowAdministrativeBoundary] =
    useState(false);
  const [showSearchByPixel, setShowSearchByPixel] = useState(false);
  const [showEnterCoordinates, setShowEnterCoordinates] = useState(false);
  const [showSearchLocation, setShowSearchLocation] = useState(false);
  const [showDrawAShape, setShowDrawAShape] = useState(false);
  const [showUploadShape, setShowUploadShape] = useState(false);
  const [explorePixelDataset, setExplorePixelDataset] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("Location");
  const [selectedLoc, setSelectedLoc] = useState();
  const searchLocation = useSelector((state) => state.searchLocation);
  const [entercoordinateLoc, setEntercoordinateLoc] = useState(null);

  const dispatch = useDispatch();

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const handleAdministrativeBoundaryClick = () => {
    setShowAdministrativeBoundary(true);
  };
  const removeNDVILayers = (layerPrefix) => {
    const layers = map.getLayers().getArray().slice();
    layers.forEach((layer) => {
      if (layer.get("name") && layer.get("name").includes(layerPrefix)) {
        map.removeLayer(layer);
      }
    });
  };
  //  useEffect(() => {
  //   if (entercoordinateLoc) {
  //     // setCountryName(searchLocation.Country || "");
  //     console.log('coordinates')
  //     dispatch(changeStateName(entercoordinateLoc.NAME_1 || ""));
  //     dispatch(changeDistrictName(entercoordinateLoc.NAME_2 || ""));
  //     dispatch(changeLevel3Name(entercoordinateLoc.NAME_3|| ""));
  //     dispatch(changeLevel4Name(entercoordinateLoc.NAME_4|| ""))
  //   }
  // }, [entercoordinateLoc]);
  //console.log(entercoordinateLoc,'entercc')
  return (
    <div className="explore-container">
      <div
        className="col-md-12"
        style={{ display: "flex", flexDirection: "row" }}
      >
        <div
          style={{
            height: "100vh",
            width: 355,
            padding: "24px 16px",
            marginLeft: "65px",
          }}
        >
          <div className="exploretext">Explore by Region</div>
          <div className="exploresecondarytext">
            Select a region or location to start exploring datasets. Choose from
            one of the options below.
          </div>
          <button
            className="explore-button"
            onClick={() => setShowSearchLocation(true)}
          >
            <img
              src="search.png"
              alt="location icon"
              style={{ marginRight: "8px" }}
            />
            Search region or location
          </button>
          <button
            className="explore-button"
            onClick={handleAdministrativeBoundaryClick}
          >
            <img
              src="world.png"
              alt="location icon"
              style={{ marginRight: "8px" }}
            />
            Administrative boundary
          </button>
          <button
            className="explore-button"
            onClick={() => setShowEnterCoordinates(true)}
          >
            <img
              src="loc-current.png"
              alt="location icon"
              style={{ marginRight: "8px" }}
            />
            Enter coordinates
          </button>
          <button
            className="explore-button"
            onClick={() => setShowDrawAShape(true)}
          >
            <img
              src="shape.png"
              alt="location icon"
              style={{ marginRight: "8px" }}
            />
            Draw a shape
          </button>
          <button
            className="explore-button"
            onClick={() => setShowUploadShape(true)}
          >
            <img
              src="shape-upload.png"
              alt="location icon"
              style={{ marginRight: "8px" }}
            />
            Upload a shape
          </button>
          <button
            className="explore-button"
            onClick={() => setShowSearchByPixel(true)}
          >
            <img
              src="search.png"
              alt="location icon"
              style={{ marginRight: "8px" }}
            />
            Search by Pixel Name
          </button>
          {showAdministrativeBoundary && (
            <AdministrativeBoundary
              removeNDVILayers={removeNDVILayers}
              selectedLocation={selectedLocation}
              setSelectedLocation={setSelectedLocation}
              showAdministrativeBoundary={showAdministrativeBoundary}
              setShowAdministrativeBoundary={setShowAdministrativeBoundary}
              countryName={countryName}
              setCountryName={setCountryName}
              tamsatDailyGraph={tamsatDailyGraph}
              setTamsatDailyGraph={setTamsatDailyGraph}
              tamsatMonthlyGraph={tamsatMonthlyGraph}
              setTamsatMonthlyGraph={setTamsatMonthlyGraph}
              chirps5kmMonthlyRainfall={chirps5kmMonthlyRainfall}
              setChirps5kmMonthlyRainfall={setChirps5kmMonthlyRainfall}
              arc210kmDailyRainfall={arc210kmDailyRainfall}
              setarc210kmDailyRainfall={setarc210kmDailyRainfall}
              modis5DayIntervalNDVIGraph={modis5DayIntervalNDVIGraph}
              setModis5DayIntervalNDVIGraph={setModis5DayIntervalNDVIGraph}
              setIsDrawerOpen={setIsDrawerOpen}
              landcover={landcover}
              setlandcover={setlandcover}
              chirps5kmDailyRainfall={chirps5kmDailyRainfall}
              setChirps5kmDailyRainfall={setChirps5kmDailyRainfall}
              era5DailyRainfallGraph={era5DailyRainfallGraph}
              setera5DailyRainfallGraph={setera5DailyRainfallGraph}
              era5MonthlyRainfallGraph={era5MonthlyRainfallGraph}
              setera5MonthlyRainfallGraph={setera5MonthlyRainfallGraph}
              era5DailyTemperatureGraph={era5DailyTemperatureGraph}
              setera5DailyTemperatureGraph={setera5DailyTemperatureGraph}
              era5MonthlyTemperatureGraph={era5MonthlyTemperatureGraph}
              setera5MonthlyTemperatureGraph={setera5MonthlyTemperatureGraph}
              gsMapDaily={gsMapDaily}
              setgsMapDaily={setgsMapDaily}
              gsMapMonthly={gsMapMonthly}
              setgsMapMonthly={setgsMapMonthly}
              sentinal2NDVI={sentinal2NDVI}
              setsentinal2NDVI={setsentinal2NDVI}
              forecastedChirpsDailyRainfallGraph={forecastedChirpsDailyRainfallGraph}
              setforecastedChirpsDailyRainfallGraph={setforecastedChirpsDailyRainfallGraph}
            />
          )}
          {showEnterCoordinates && (
            <EnterCoordinates
              showEnterCoordinates={setShowEnterCoordinates}
              countryName={countryName}
              setCountryName={setCountryName}
              tamsatDailyGraph={tamsatDailyGraph}
              setTamsatDailyGraph={setTamsatDailyGraph}
              tamsatMonthlyGraph={tamsatMonthlyGraph}
              setTamsatMonthlyGraph={setTamsatMonthlyGraph}
              chirps5kmMonthlyRainfall={chirps5kmMonthlyRainfall}
              setChirps5kmMonthlyRainfall={setChirps5kmMonthlyRainfall}
              arc210kmDailyRainfall={arc210kmDailyRainfall}
              setarc210kmDailyRainfall={setarc210kmDailyRainfall}
              modis5DayIntervalNDVIGraph={modis5DayIntervalNDVIGraph}
              setModis5DayIntervalNDVIGraph={setModis5DayIntervalNDVIGraph}
              setIsDrawerOpen={setIsDrawerOpen}
              landcover={landcover}
              setlandcover={setlandcover}
              chirps5kmDailyRainfall={chirps5kmDailyRainfall}
              setChirps5kmDailyRainfall={setChirps5kmDailyRainfall}
              era5DailyRainfallGraph={era5DailyRainfallGraph}
              setera5DailyRainfallGraph={setera5DailyRainfallGraph}
              era5MonthlyRainfallGraph={era5MonthlyRainfallGraph}
              setera5MonthlyRainfallGraph={setera5MonthlyRainfallGraph}
              era5DailyTemperatureGraph={era5DailyTemperatureGraph}
              setera5DailyTemperatureGraph={setera5DailyTemperatureGraph}
              era5MonthlyTemperatureGraph={era5MonthlyTemperatureGraph}
              setera5MonthlyTemperatureGraph={setera5MonthlyTemperatureGraph}
              gsMapDaily={gsMapDaily}
              setgsMapDaily={setgsMapDaily}
              gsMapMonthly={gsMapMonthly}
              setgsMapMonthly={setgsMapMonthly}
              sentinal2NDVI={sentinal2NDVI}
              setsentinal2NDVI={setsentinal2NDVI}
              entercoordinateLoc={entercoordinateLoc}
              setEntercoordinateLoc={setEntercoordinateLoc}
            />
          )}
          {showSearchLocation && (
            <SearchLocation
              showAdministrativeBoundary={showAdministrativeBoundary}
              setShowAdministrativeBoundary={setShowAdministrativeBoundary}
              selectedLocation={selectedLocation}
              setSelectedLocation={setSelectedLocation}
              selectedLoc={selectedLoc}
              setSelectedLoc={setSelectedLoc}
              showSearchLocation={setShowSearchLocation}
              countryName={countryName}
              setCountryName={setCountryName}
              tamsatDailyGraph={tamsatDailyGraph}
              setTamsatDailyGraph={setTamsatDailyGraph}
              tamsatMonthlyGraph={tamsatMonthlyGraph}
              setTamsatMonthlyGraph={setTamsatMonthlyGraph}
              chirps5kmMonthlyRainfall={chirps5kmMonthlyRainfall}
              setChirps5kmMonthlyRainfall={setChirps5kmMonthlyRainfall}
              arc210kmDailyRainfall={arc210kmDailyRainfall}
              setarc210kmDailyRainfall={setarc210kmDailyRainfall}
              modis5DayIntervalNDVIGraph={modis5DayIntervalNDVIGraph}
              setModis5DayIntervalNDVIGraph={setModis5DayIntervalNDVIGraph}
              setIsDrawerOpen={setIsDrawerOpen}
              landcover={landcover}
              setlandcover={setlandcover}
              chirps5kmDailyRainfall={chirps5kmDailyRainfall}
              setChirps5kmDailyRainfall={setChirps5kmDailyRainfall}
              era5DailyRainfallGraph={era5DailyRainfallGraph}
              setera5DailyRainfallGraph={setera5DailyRainfallGraph}
              era5MonthlyRainfallGraph={era5MonthlyRainfallGraph}
              setera5MonthlyRainfallGraph={setera5MonthlyRainfallGraph}
              era5DailyTemperatureGraph={era5DailyTemperatureGraph}
              setera5DailyTemperatureGraph={setera5DailyTemperatureGraph}
              era5MonthlyTemperatureGraph={era5MonthlyTemperatureGraph}
              setera5MonthlyTemperatureGraph={setera5MonthlyTemperatureGraph}
              gsMapDaily={gsMapDaily}
              setgsMapDaily={setgsMapDaily}
              gsMapMonthly={gsMapMonthly}
              setgsMapMonthly={setgsMapMonthly}
              sentinal2NDVI={sentinal2NDVI}
              setsentinal2NDVI={setsentinal2NDVI}
            />
          )}
          {showDrawAShape && (
            <DrawAShape
              setShowDrawAShape={setShowDrawAShape}
              countryName={countryName}
              setCountryName={setCountryName}
              tamsatDailyGraph={tamsatDailyGraph}
              setTamsatDailyGraph={setTamsatDailyGraph}
              tamsatMonthlyGraph={tamsatMonthlyGraph}
              setTamsatMonthlyGraph={setTamsatMonthlyGraph}
              chirps5kmMonthlyRainfall={chirps5kmMonthlyRainfall}
              setChirps5kmMonthlyRainfall={setChirps5kmMonthlyRainfall}
              arc210kmDailyRainfall={arc210kmDailyRainfall}
              setarc210kmDailyRainfall={setarc210kmDailyRainfall}
              modis5DayIntervalNDVIGraph={modis5DayIntervalNDVIGraph}
              setModis5DayIntervalNDVIGraph={setModis5DayIntervalNDVIGraph}
              setIsDrawerOpen={setIsDrawerOpen}
              landcover={landcover}
              setlandcover={setlandcover}
              chirps5kmDailyRainfall={chirps5kmDailyRainfall}
              setChirps5kmDailyRainfall={setChirps5kmDailyRainfall}
              era5DailyRainfallGraph={era5DailyRainfallGraph}
              setera5DailyRainfallGraph={setera5DailyRainfallGraph}
              era5MonthlyRainfallGraph={era5MonthlyRainfallGraph}
              setera5MonthlyRainfallGraph={setera5MonthlyRainfallGraph}
              era5DailyTemperatureGraph={era5DailyTemperatureGraph}
              setera5DailyTemperatureGraph={setera5DailyTemperatureGraph}
              era5MonthlyTemperatureGraph={era5MonthlyTemperatureGraph}
              setera5MonthlyTemperatureGraph={setera5MonthlyTemperatureGraph}
              gsMapDaily={gsMapDaily}
              setgsMapDaily={setgsMapDaily}
              gsMapMonthly={gsMapMonthly}
              setgsMapMonthly={setgsMapMonthly}
              sentinal2NDVI={sentinal2NDVI}
              setsentinal2NDVI={setsentinal2NDVI}
            />
          )}
          {showUploadShape && (
            <UploadAShape
              showUploadShape={setShowUploadShape}
              countryName={countryName}
              setCountryName={setCountryName}
              tamsatDailyGraph={tamsatDailyGraph}
              setTamsatDailyGraph={setTamsatDailyGraph}
              tamsatMonthlyGraph={tamsatMonthlyGraph}
              setTamsatMonthlyGraph={setTamsatMonthlyGraph}
              chirps5kmMonthlyRainfall={chirps5kmMonthlyRainfall}
              setChirps5kmMonthlyRainfall={setChirps5kmMonthlyRainfall}
              arc210kmDailyRainfall={arc210kmDailyRainfall}
              setarc210kmDailyRainfall={setarc210kmDailyRainfall}
              modis5DayIntervalNDVIGraph={modis5DayIntervalNDVIGraph}
              setModis5DayIntervalNDVIGraph={setModis5DayIntervalNDVIGraph}
              setIsDrawerOpen={setIsDrawerOpen}
              landcover={landcover}
              setlandcover={setlandcover}
              chirps5kmDailyRainfall={chirps5kmDailyRainfall}
              setChirps5kmDailyRainfall={setChirps5kmDailyRainfall}
              era5DailyRainfallGraph={era5DailyRainfallGraph}
              setera5DailyRainfallGraph={setera5DailyRainfallGraph}
              era5MonthlyRainfallGraph={era5MonthlyRainfallGraph}
              setera5MonthlyRainfallGraph={setera5MonthlyRainfallGraph}
              era5DailyTemperatureGraph={era5DailyTemperatureGraph}
              setera5DailyTemperatureGraph={setera5DailyTemperatureGraph}
              era5MonthlyTemperatureGraph={era5MonthlyTemperatureGraph}
              setera5MonthlyTemperatureGraph={setera5MonthlyTemperatureGraph}
              gsMapDaily={gsMapDaily}
              setgsMapDaily={setgsMapDaily}
              gsMapMonthly={gsMapMonthly}
              setgsMapMonthly={setgsMapMonthly}
              sentinal2NDVI={sentinal2NDVI}
              setsentinal2NDVI={setsentinal2NDVI}
            />
          )}
          {showSearchByPixel && (
            <SearchByPixel
              setShowSearchByPixel={setShowSearchByPixel}
              countryName={countryName}
              setCountryName={setCountryName}
              tamsatDailyGraph={tamsatDailyGraph}
              setTamsatDailyGraph={setTamsatDailyGraph}
              tamsatMonthlyGraph={tamsatMonthlyGraph}
              setTamsatMonthlyGraph={setTamsatMonthlyGraph}
              chirps5kmMonthlyRainfall={chirps5kmMonthlyRainfall}
              setChirps5kmMonthlyRainfall={setChirps5kmMonthlyRainfall}
              arc210kmDailyRainfall={arc210kmDailyRainfall}
              setarc210kmDailyRainfall={setarc210kmDailyRainfall}
              modis5DayIntervalNDVIGraph={modis5DayIntervalNDVIGraph}
              setModis5DayIntervalNDVIGraph={setModis5DayIntervalNDVIGraph}
              setIsDrawerOpen={setIsDrawerOpen}
              landcover={landcover}
              setlandcover={setlandcover}
              chirps5kmDailyRainfall={chirps5kmDailyRainfall}
              setChirps5kmDailyRainfall={setChirps5kmDailyRainfall}
              era5DailyRainfallGraph={era5DailyRainfallGraph}
              setera5DailyRainfallGraph={setera5DailyRainfallGraph}
              era5MonthlyRainfallGraph={era5MonthlyRainfallGraph}
              setera5MonthlyRainfallGraph={setera5MonthlyRainfallGraph}
              era5DailyTemperatureGraph={era5DailyTemperatureGraph}
              setera5DailyTemperatureGraph={setera5DailyTemperatureGraph}
              era5MonthlyTemperatureGraph={era5MonthlyTemperatureGraph}
              setera5MonthlyTemperatureGraph={setera5MonthlyTemperatureGraph}
              gsMapDaily={gsMapDaily}
              setgsMapDaily={setgsMapDaily}
              gsMapMonthly={gsMapMonthly}
              setgsMapMonthly={setgsMapMonthly}
              sentinal2NDVI={sentinal2NDVI}
              setsentinal2NDVI={setsentinal2NDVI}
              explorePixelDataset={explorePixelDataset}
              setExplorePixelDataset={setExplorePixelDataset}
            />
          )}
        </div>
        {/*<div style={{ position: 'relative', height: '100vh', width: '90vw' }}>
          {countryName === "Tanzania" || countryName === "Zambia" || countryName === "Bangladesh" || countryName === "Sudan" || countryName === "Kenya" ? (
            <ZoomableMap countryName={countryName}/>
             <OlMap2 ward={level3Data} state={stateName} />*/}
        <div
          style={{
            position: "relative",
            height: "calc(100vh - 45px)",
            width: "calc(100vw - 430px)",
          }}
        >
          <MapView countryName={countryName} />
        </div>
        {explorePixelDataset ? (
          <PixelMapDrawer
            toggleDrawer={toggleDrawer}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            stateName={stateName}
            countryName={countryName}
            districtName={districtName}
            level3Data={level3Data}
            level4Data={level4Data}
            tamsatDailyGraph={tamsatDailyGraph}
            tamsatMonthlyGraph={tamsatMonthlyGraph}
            chirps5kmMonthlyRainfall={chirps5kmMonthlyRainfall}
            arc210kmDailyRainfall={arc210kmDailyRainfall}
            modis5DayIntervalNDVIGraph={modis5DayIntervalNDVIGraph}
            landcover={landcover}
            setlandcover={setlandcover}
            chirps5kmDailyRainfall={chirps5kmDailyRainfall}
            setChirps5kmDailyRainfall={setChirps5kmDailyRainfall}
            era5DailyRainfallGraph={era5DailyRainfallGraph}
            setera5DailyRainfallGraph={setera5DailyRainfallGraph}
            era5MonthlyRainfallGraph={era5MonthlyRainfallGraph}
            setera5MonthlyRainfallGraph={setera5MonthlyRainfallGraph}
            era5DailyTemperatureGraph={era5DailyTemperatureGraph}
            setera5DailyTemperatureGraph={setera5DailyTemperatureGraph}
            era5MonthlyTemperatureGraph={era5MonthlyTemperatureGraph}
            setera5MonthlyTemperatureGraph={setera5MonthlyTemperatureGraph}
            gsMapDaily={gsMapDaily}
            setgsMapDaily={setgsMapDaily}
            gsMapMonthly={gsMapMonthly}
            setgsMapMonthly={setgsMapMonthly}
            sentinal2NDVI={sentinal2NDVI}
            setsentinal2NDVI={setsentinal2NDVI}
            showSearchByPixel={showSearchByPixel}
            explorePixelDataset={explorePixelDataset}
          />
        ) : (
          <MapDrawer
            removeNDVILayers={removeNDVILayers}
            toggleDrawer={toggleDrawer}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            stateName={stateName}
            countryName={countryName}
            districtName={districtName}
            level3Data={level3Data}
            level4Data={level4Data}
            tamsatDailyGraph={tamsatDailyGraph}
            tamsatMonthlyGraph={tamsatMonthlyGraph}
            chirps5kmMonthlyRainfall={chirps5kmMonthlyRainfall}
            arc210kmDailyRainfall={arc210kmDailyRainfall}
            modis5DayIntervalNDVIGraph={modis5DayIntervalNDVIGraph}
            landcover={landcover}
            setlandcover={setlandcover}
            chirps5kmDailyRainfall={chirps5kmDailyRainfall}
            setChirps5kmDailyRainfall={setChirps5kmDailyRainfall}
            era5DailyRainfallGraph={era5DailyRainfallGraph}
            setera5DailyRainfallGraph={setera5DailyRainfallGraph}
            era5MonthlyRainfallGraph={era5MonthlyRainfallGraph}
            setera5MonthlyRainfallGraph={setera5MonthlyRainfallGraph}
            era5DailyTemperatureGraph={era5DailyTemperatureGraph}
            setera5DailyTemperatureGraph={setera5DailyTemperatureGraph}
            era5MonthlyTemperatureGraph={era5MonthlyTemperatureGraph}
            setera5MonthlyTemperatureGraph={setera5MonthlyTemperatureGraph}
            gsMapDaily={gsMapDaily}
            setgsMapDaily={setgsMapDaily}
            gsMapMonthly={gsMapMonthly}
            setgsMapMonthly={setgsMapMonthly}
            sentinal2NDVI={sentinal2NDVI}
            setsentinal2NDVI={setsentinal2NDVI}
            showSearchByPixel={showSearchByPixel}
            forecastedChirpsDailyRainfallGraph={forecastedChirpsDailyRainfallGraph}
            setforecastedChirpsDailyRainfallGraph={setforecastedChirpsDailyRainfallGraph}
          />
        )}
      </div>
    </div>
  );
};

export default Explore;
