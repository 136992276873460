import React, { useState, useEffect } from "react";
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Auth } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { changeIndexName, changeLandcoverswitch, changeNdvimodisswitch } from "../../redux/action";



const Datasets = (props) => {
  const [rainfallOpen, setRainfallOpen] = useState(false);
  const [vegetationOpen, setVegetationOpen] = useState(false);
  const [landcoverOpen, setLandcoverOpen] = useState(false);
  const [tempOpen, setTempOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [DatasetOptions, setDatasetOptions] = useState([]);
  const landcoverswitch = useSelector((state) => state.landcoverswitch);
  const ndvimodisswitch = useSelector((state) => state.ndvimodisswitch);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const searchLocation = useSelector((state) => state.searchLocation);
  useEffect(() => {
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
  
      let apiUrl = "/api/explore/datasets";
      let params = {};
  
      if (searchLocation && searchLocation.Country) {
        apiUrl += `?country=${searchLocation.Country}`;
      } else if (props.countryName) {
        apiUrl += `?country=${props.countryName}`;
      }
  
      fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setDatasetOptions(data);
          setLoading(false);
        });
    }
  
    genToken();
  }, [props.countryName, searchLocation]);
  

  const handleOptionToggle = (option) => {
    props.setIsDrawerOpen(true);
    if(option === "Landcover")
    dispatch(changeLandcoverswitch(!landcoverswitch))
    if(option === "MODIS 5 Day Interval NDVI")
    dispatch(changeNdvimodisswitch(!ndvimodisswitch))
    if (selectedOptions.length === 4 && !selectedOptions.includes(option)) {
      toast.error(
        <div>
          <div style={{ fontSize: '14px', marginBottom: '5px', fontWeight: 'bold' }}>
            Maximum Data Sets Selected
          </div>

          <div style={{ marginTop: '5px', fontSize: '12px', fontWeight: 400 }}>
            Up to four data sets can be displayed for any selected location
          </div>
        </div>,
        {
          autoClose: 5000,
          hideProgressBar: true,
        }
      );
      return;
    }

    const updatedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter((selectedOption) => selectedOption !== option)
      : [...selectedOptions, option];

    setSelectedOptions(updatedOptions);

    switch (option) {
      case "TAMSAT Monthly Rainfall":
        dispatch(changeIndexName('TAMSAT Monthly Rainfall'))
        props.setTamsatMonthlyGraph(!props.tamsatMonthlyGraph);
        break;
      case "Chirps 5km Daily Rainfall":
        dispatch(changeIndexName('Chirps 5km Daily Rainfall'))
        props.setChirps5kmDailyRainfall(!props.chirps5kmDailyRainfall);
        break;
      case "Chirps 5km Monthly Rainfall":
        dispatch(changeIndexName('Chirps 5km Monthly Rainfall'))
        props.setChirps5kmMonthlyRainfall(!props.chirps5kmMonthlyRainfall);
        break;
      case "ARC2 10km Daily Rainfall":
        dispatch(changeIndexName('ARC2 10km Daily Rainfall'))
        props.setarc210kmDailyRainfall(!props.arc210kmDailyRainfall);
        break;
      case "TAMSAT Daily Rainfall":
        dispatch(changeIndexName('TAMSAT Daily Rainfall'))
        props.setTamsatDailyGraph(!props.tamsatDailyGraph);
        break;
      case "MODIS 10 Day Interval NDVI":
        props.setModis5DayIntervalNDVIGraph(!props.modis5DayIntervalNDVIGraph);
        dispatch(changeIndexName('MODIS 10 Day Interval NDVI'))
        break;
        case "MODIS 16 Day Interval NDVI":
          props.setModis5DayIntervalNDVIGraph(!props.modis5DayIntervalNDVIGraph);
          dispatch(changeIndexName('MODIS 16 Day Interval NDVI'))
          break;
      case "ECMWF/ERA5 Daily Rainfall":
        props.setera5DailyRainfallGraph(!props.era5DailyRainfallGraph);
        dispatch(changeIndexName('ECMWF/ERA5 Daily Rainfall'))
        break;
      case "ECMWF/ERA5 Monthly Rainfall":
        props.setera5MonthlyRainfallGraph(!props.era5MonthlyRainfallGraph);
        dispatch(changeIndexName('ECMWF/ERA5 Monthly Rainfall'))
        break;
      case "ECMWF/ERA5 Daily Temperature":
        props.setera5DailyTemperatureGraph(!props.era5DailyTemperatureGraph);
        dispatch(changeIndexName('ECMWF/ERA5 Daily Temperature'))
        break;
      case "GSMAP Daily Rainfall":
        props.setgsMapDaily(!props.gsMapDaily);
        dispatch(changeIndexName('GSMAP Daily Rainfall'))
        break;
      case "GSMAP Monthly Rainfall":
        props.setgsMapMonthly(!props.gsMapMonthly);
        dispatch(changeIndexName('GSMAP Monthly Rainfall'))
        break;
      case "SENTINEL-2 5 Day interval NDVI":
        props.setsentinal2NDVI(!props.sentinal2NDVI);
        dispatch(changeIndexName('SENTINEL-2 5 Day interval NDVI'))
        break;
      case "ECMWF/ERA5 Monthly Temperature":
        props.setera5MonthlyTemperatureGraph(!props.era5MonthlyTemperatureGraph);
        dispatch(changeIndexName('ECMWF/ERA5 Monthly Temperature'))
        break;
      case "Forecasted Chirps Daily Rainfall":
        props.setforecastedChirpsDailyRainfallGraph(!props.forecastedChirpsDailyRainfallGraph);
        dispatch(changeIndexName('Forecasted Chirps Daily Rainfall'))
        break;
      case "Landcover":
        props.setlandcover(!props.landcover);
        dispatch(changeIndexName('Landcover'))
        break;
      default:
        break;
    }
  };
  const renderOptions = (options) => {
    return (
      <div style={{ width: '300px', padding: '8px 12px' }}>
        {options.map((option) => (
          <div
            key={option}
            style={{
              color: '#1C2024',
              display: 'flex',
              padding: '8px 12px',
              justifyContent: 'space-between',
              alignItems: 'center',
              fontFamily: 'Inter-Medium, Helvetica',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '20px',
            }}
            onClick={() => handleOptionToggle(option)}
          >
            <span>{option}</span>
            <input type="checkbox"
             checked={
              selectedOptions.includes(option)
            } 
            />
          </div>
        ))}
      </div>
    );
  };

  const accordionStyle = (isOpen) => ({
    width: '300px',
    padding: '4px 8px',
    background: isOpen ? '#29290507' : 'white',
    color: '#1C2024',
    fontFamily: 'Inter-Medium, Helvetica',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '26px',
    letterSpacing: '-0.04px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: isOpen ? '2px' : '24px',
  });

  const iconStyle = {
    marginRight: '8px',
  };

  return (
    <div style={{ height: '370px', overflowY: 'scroll', bottom: '60px' }}>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ width: '400px', padding: '16px', textAlign: 'initial', color: '#21201C' }}
      />
       {loading ? ( 
        <div>Loading Datasets...</div>
      ) : (
        <>
      {/* Rainfall Accordion */}
      <div onClick={() => setRainfallOpen(!rainfallOpen)}>
        <div style={accordionStyle(rainfallOpen)}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src="raindrop.png" alt="Raindrop" style={iconStyle} />
            <span>Rainfall</span>
          </div>
          {rainfallOpen ? <FaAngleUp /> : <FaAngleDown />}
        </div>
      </div>
      {rainfallOpen && renderOptions(DatasetOptions.rainfall)}

      {/* Vegetation Accordion */}
      <div onClick={() => setVegetationOpen(!vegetationOpen)}>
        <div style={accordionStyle(vegetationOpen)}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src="plant.png" alt="Vegetation" style={iconStyle} />
            <span>Vegetation</span>
          </div>
          {vegetationOpen ? <FaAngleUp /> : <FaAngleDown />}
        </div>
      </div>
      {vegetationOpen && renderOptions(DatasetOptions.ndvi)}

      {/* Landcover Accordion */}
      <div onClick={() => setLandcoverOpen(!landcoverOpen)}>
        <div style={accordionStyle(landcoverOpen)}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src="mountain.png" alt="Landcover" style={iconStyle} />
            <span>Landcover</span>
          </div>
          {landcoverOpen ? <FaAngleUp /> : <FaAngleDown />}
        </div>
      </div>
      {landcoverOpen && renderOptions(['Landcover'])}

     <div onClick={() => setTempOpen(!tempOpen)}>
        <div style={accordionStyle(tempOpen)}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src="thermometer.png" alt="Temperature" style={iconStyle} />
            <span>Temperature</span>
          </div>
          {tempOpen ? <FaAngleUp /> : <FaAngleDown />}
        </div>
      </div>
      {tempOpen && renderOptions(DatasetOptions.temperature)}
    
        </>
        )}
      </div>
  );
};

export default Datasets;
