import { BsArrowsCollapse } from "react-icons/bs";
import "../../ManualStep2.css";
import { TbArrowMoveUp } from "react-icons/tb";
import { TbArrowMoveDown } from "react-icons/tb";
import React, { useState, useEffect } from "react";
import PayoutChart from "./PayoutChart";
import Select from "react-select";
import { Auth } from "aws-amplify";
import { useSelector } from "react-redux";

const cardStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "250px",
  height: "auto",
  borderRadius: "12px",
  border: "1px solid rgba(26, 26, 0, 0.16)",
  padding: "16px",
  gap: "3px",
};
const step2cardStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "250px",
  height: "auto",
  borderRadius: "12px",
  border: "1px solid rgba(26, 26, 0, 0.16)",
  gap: "3px",
  padding: "20px",
};
const totalIUCardStyle = {
  ...cardStyle,
  flexDirection: "row",
  padding: "25px",
};
const containerStyle = {
  display: "flex",
  gap: "16px",
  width: "100%",
};

const ManualSummary = (props) => {
  const MaxPremium = useSelector((state) => state.MaxPremium);
  const MinPremium = useSelector((state) => state.MinPremium);
  const MeanPremium = useSelector((state) => state.MeanPremium);
  const CommercialPremium = useSelector((state) => state.CommercialPremium);
  const CommercialMaxPremium = useSelector(
    (state) => state.CommercialMaxPremium
  );
  const CommercialMinPremium = useSelector(
    (state) => state.CommercialMinPremium
  );
  const TotalIU = useSelector((state) => state.TotalIU);
  const step = useSelector((state) => state.step);
  const typeOfProduct = useSelector((state) => state.typeOfProduct);
  const productName = useSelector((state) => state.productName);
  const [iuSelected, setIUSelected] = useState("");
  const [iuObject, setIUObject] = useState([]);
  const coverMaxPayout = useSelector((state) => state.coverMaxPayout);
  const IUList = useSelector((state) => state.IUList);
  const NewIUList = useSelector((state) => state.NewIUList);
  const [iuArray, setIUArray] = useState([]);
  const policyDetails = useSelector((state) => state.policyDetails);
  const coverName = useSelector((state) => state.coverName);
  const coverDetails = useSelector((state) => state.coverDetails);
  const numberOfCovers = useSelector((state) => state.numberOfCovers);

  useEffect(() => {
    let iuArrayTemp = [];
    for (let i in IUList) {
      if (IUList[i].level4 != null)
        iuArrayTemp.push(
          `${IUList[i]["level1"]}->${IUList[i]["level2"]}->${IUList[i]["level3"]}->${IUList[i]["level4"]}`
        );
      else if (IUList[i].level3 != null)
        iuArrayTemp.push(
          `${IUList[i]["level1"]}->${IUList[i]["level2"]}->${IUList[i]["level3"]}`
        );
      else if (IUList[i].level2 != null)
        iuArrayTemp.push(`${IUList[i]["level1"]}->${IUList[i]["level2"]}`);
      else iuArrayTemp.push(`${IUList[i]["level1"]}`);
    }
    setIUArray(iuArrayTemp);

    let iuObjectTemp = iuArrayTemp.map((iu) => ({
      label: iu,
      value: iu,
    }));
    setIUObject(iuObjectTemp);

    if (policyDetails) {
      let policyArrayTemp = [];
      for (let i in IUList) {
        const policy = IUList[i];
        if (policy.level4 != null)
          policyArrayTemp.push(
            `${policy["level1"]}->${policy["level2"]}->${policy["level3"]}->${policy["level4"]}`
          );
        else if (policy.level3 != null)
          policyArrayTemp.push(
            `${policy["level1"]}->${policy["level2"]}->${policy["level3"]}`
          );
        else if (policy.level2 != null)
          policyArrayTemp.push(`${policy["level1"]}->${policy["level2"]}`);
        else policyArrayTemp.push(`${policy["level1"]}`);
      }

      let policyObjectTemp = policyArrayTemp.map((policy) => ({
        label: policy,
        value: policy,
      }));
      setIUObject(policyObjectTemp);
    }
  }, [IUList, policyDetails]);

  const selectedIUData = iuSelected
    ? NewIUList.find((iu) => {
        const levels = [
          iu.level1,
          iu.level2,
          iu.level3 || "", // Treat null as an empty string for comparison
          iu.level4 || "", // Treat null as an empty string for comparison
        ];
        const selectedLevels = iuSelected.split("->");

        return levels
          .slice(0, selectedLevels.length)
          .every((level, index) => level === selectedLevels[index]);
      })
    : null;

  useEffect(() => {
    async function genToken() {
      const user = await Auth.currentAuthenticatedUser();
      const email = user.attributes.email;
      if (iuSelected != "") {
        fetch(`/api/pricing/showPayout`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            product_type: typeOfProduct,
            product_name: productName,
            level1: null || iuSelected.split("->")[0],
            level2: null || iuSelected.split("->")[1],
            level3: null || iuSelected.split("->")[2],
            level4: null || iuSelected.split("->")[3],
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            props.setPayout(data);
          })
          .catch((err) => console.log("ERR:", err));
      }
    }
    genToken();
  }, [iuSelected]);

  if (props.loading) {
    return (
      <div className="loading-container">
        <div className="loading-dots">
          <div className="dot dot1"></div>
          <div className="dot dot2"></div>
          <div className="dot dot3"></div>
        </div>
        <div className="loading-text">Please Wait...</div>
        <div className="loading-subtext">Your Premium is being calculated</div>
      </div>
    );
  }
  const labelStyle = {
    color: "#000",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "16px",
    letterSpacing: "-0.04px",
    alignItems: "left",
    marginBottom: "4px",
  };

  const selectStyle = {
    width: "312px",
    marginBottom: "16px",
  };

  const inputStyle = {
    width: "100px",
    marginLeft: "16px",
    borderRadius: "8px",
    border: "1px solid var(--Sand-Light-8, #C8C7C1)",
    background: "var(--Sand-Light-2, #F9F9F8)",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "16px",
    letterSpacing: "-0.04px",
    padding: "8px",
  };

  return (
    <div>
      <div
        className="card"
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "30px",
          width: "100%",
        }}
      >
        {step === 2 || step === 1 ? (
          <div style={containerStyle}>
            <div style={totalIUCardStyle}>
              <BsArrowsCollapse
                size={40}
                style={{
                  color: "#ED5B00",
                  background: "#FFF1E7",
                  padding: "5px",
                  margin: "5px",
                  borderRadius: "12px",
                }}
              />
              <div>
                <div className="maintext">Total IU’s</div>
                <div className="value">{TotalIU ? TotalIU : "-"}</div>
              </div>
            </div>
            <div style={step2cardStyle}>
              <TbArrowMoveUp
                size={40}
                style={{
                  color: "#ED5B00",
                  background: "#FFF1E7",
                  padding: "5px",
                  margin: "5px",
                  borderRadius: "12px",
                }}
              />
              <div>
                <div className="maintext">Avg premium</div>
                <div className="value">{MeanPremium !== undefined ? MeanPremium : "-"}</div>
              </div>
            </div>
            <div style={step2cardStyle}>
              <TbArrowMoveDown
                size={40}
                style={{
                  color: "#ED5B00",
                  background: "#FFF1E7",
                  padding: "5px",
                  margin: "5px",
                  borderRadius: "12px",
                }}
              />
              <div>
                <div className="maintext">Min premium</div>
                <div className="value">{MinPremium !== undefined ? MinPremium : "-"}</div>
              </div>
            </div>
            <div style={step2cardStyle}>
              <BsArrowsCollapse
                size={40}
                style={{
                  color: "#ED5B00",
                  background: "#FFF1E7",
                  padding: "5px",
                  margin: "5px",
                  borderRadius: "12px",
                }}
              />
              <div>
                <div className="maintext">Max Premium</div>
                <div className="value">{MaxPremium !== undefined ? MaxPremium : "-"}</div>
              </div>
            </div>
          </div>
        ) : (
          <div style={containerStyle}>
            <div style={totalIUCardStyle}>
              <BsArrowsCollapse
                size={40}
                style={{
                  color: "#ED5B00",
                  background: "#FFF1E7",
                  padding: "5px",
                  margin: "5px",
                  borderRadius: "12px",
                }}
              />
              <div>
                <div className="maintext">Total IU’s</div>
                <div className="value">{TotalIU ? TotalIU : "-"}</div>
              </div>
            </div>
            <div style={cardStyle}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <TbArrowMoveUp
                  size={40}
                  style={{
                    color: "#ED5B00",
                    background: "#FFF1E7",
                    padding: "5px",
                    margin: "5px",
                    borderRadius: "12px",
                  }}
                />
                <div className="maintext">Avg premium</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "25%",
                  marginTop: "10px",
                }}
              >
                <div>
                  <div className="maintext">Pure</div>
                  <div className="maintext">
                    {MeanPremium !== undefined ? MeanPremium : "-"}
                  </div>
                </div>
                <div
                  style={{ borderLeft: "1px solid black", height: "40px" }}
                ></div>
                <div>
                  <div className="maintext">Gross</div>
                  <div className="maintext">
                    {CommercialPremium !== undefined ? CommercialPremium : "-"}
                  </div>
                </div>
              </div>
            </div>
            <div style={cardStyle}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <TbArrowMoveDown
                  size={40}
                  style={{
                    color: "#ED5B00",
                    background: "#FFF1E7",
                    padding: "5px",
                    margin: "5px",
                    borderRadius: "12px",
                  }}
                />
                <div className="maintext">Min premium</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "30%",
                  marginTop: "10px",
                }}
              >
                <div>
                  <div className="maintext">Pure</div>
                  <div className="maintext">
                    {MinPremium !== undefined ? MinPremium : "-"}
                  </div>
                </div>
                <div
                  style={{ borderLeft: "1px solid black", height: "40px" }}
                ></div>
                <div>
                  <div className="maintext">Gross</div>
                  <div className="maintext">
                    {CommercialMinPremium !== undefined ? CommercialMinPremium : "-"}
                  </div>
                </div>
              </div>
            </div>
            <div style={cardStyle}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <BsArrowsCollapse
                  size={40}
                  style={{
                    color: "#ED5B00",
                    background: "#FFF1E7",
                    padding: "5px",
                    margin: "5px",
                    borderRadius: "12px",
                  }}
                />
                <div className="maintext">Max premium</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "30%",
                  marginTop: "10px",
                }}
              >
                <div>
                  <div className="maintext">Pure</div>
                  <div className="maintext">
                    {MaxPremium !== undefined ? MaxPremium : "-"}
                  </div>
                </div>
                <div
                  style={{ borderLeft: "1px solid black", height: "40px" }}
                ></div>
                <div>
                  <div className="maintext">Gross</div>
                  <div className="maintext">
                    {CommercialMaxPremium !== undefined ? CommercialMaxPremium : "-"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div style={{ width: "312px", marginTop: "30px", textAlign: "initial" }}>
        <label htmlFor="iuSelect" style={labelStyle}>
          Choose IU
        </label>
        <Select
          id="iuSelect"
          className="custom-select"
          value={iuSelected ? { label: iuSelected, value: iuSelected } : null}
          onChange={(obj) => {
            setIUSelected(obj.value);
          }}
          options={iuObject}
          placeholder="Select IU"
          style={selectStyle}
        />
      </div>

      {iuSelected !== "" ? (
        <div
          id="chart1"
          style={{
            fontSize: "14px",
            lineHeight: "26px",
            letterSpacing: "-0.04px",
            marginTop: "20px",
            textAlign: "left",
            marginBottom: "20px",
          }}
        >
          IU 15 Historical Payout Analysis (Burncost Calculation)
          <PayoutChart data={props.payout} />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px", // Add padding around the container
          }}
        >
          <div
            style={{
              display: "flex",
              width: "310px",
              padding: "16px",
              alignItems: "center",
              gap: "12px",
              borderRadius: "6px",
              background: "rgba(1, 97, 158, 0.07)",
            }}
          >
            <p>Choose IU to start exploring the visualization</p>
          </div>
        </div>
      )}
      {iuSelected && selectedIUData && (
        <table
          style={{
            marginTop: "20px",
            borderCollapse: "collapse",
            width: "100%",
          }}
        >
          <thead>
            <tr style={{ backgroundColor: "#F9F1FE" }}>
              <th
                style={{
                  borderBottom: "none",
                  padding: "8px",
                  fontSize: "12px",
                }}
              >
                Cover Name
              </th>
              <th
                style={{
                  borderBottom: "none",
                  padding: "8px",
                  fontSize: "12px",
                }}
              >
                Weather Trigger
              </th>
              <th
                style={{
                  borderBottom: "none",
                  padding: "8px",
                  fontSize: "12px",
                }}
              >
                Weather Exit
              </th>
              <th
                style={{
                  borderBottom: "none",
                  padding: "8px",
                  fontSize: "12px",
                }}
              >
                Max Payout
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  borderTop: "none",
                  borderBottom: "1px solid #C8C7C1",
                  padding: "8px",
                  color: "#706F6C",
                  fontSize: "12px",
                }}
              >
                {coverName}
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderBottom: "1px solid #C8C7C1",
                  padding: "8px",
                  color: "#706F6C",
                  fontSize: "12px",
                }}
              >
                {selectedIUData.cover_1_trigger}
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderBottom: "1px solid #C8C7C1",
                  padding: "8px",
                  color: "#706F6C",
                  fontSize: "12px",
                }}
              >
                {selectedIUData.cover_1_exit}
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderBottom: "1px solid #C8C7C1",
                  padding: "8px",
                  color: "#706F6C",
                  fontSize: "12px",
                }}
              >
                {coverMaxPayout}
              </td>
            </tr>
            {numberOfCovers == 2 && (
              <tr style={{ marginBottom: "50px" }}>
                <td
                  style={{
                    borderTop: "none",
                    borderBottom: "1px solid #C8C7C1",
                    padding: "8px",
                    color: "#706F6C",
                    fontSize: "12px",
                  }}
                >
                  {coverDetails.cover2Name}
                </td>
                <td
                  style={{
                    borderTop: "none",
                    borderBottom: "1px solid #C8C7C1",
                    padding: "8px",
                    color: "#706F6C",
                    fontSize: "12px",
                  }}
                >
                  {selectedIUData.cover_2_trigger}
                </td>
                <td
                  style={{
                    borderTop: "none",
                    borderBottom: "1px solid #C8C7C1",
                    padding: "8px",
                    color: "#706F6C",
                    fontSize: "12px",
                  }}
                >
                  {selectedIUData.cover_2_exit}
                </td>
                <td
                  style={{
                    borderTop: "none",
                    borderBottom: "1px solid #C8C7C1",
                    padding: "8px",
                    color: "#706F6C",
                    fontSize: "12px",
                  }}
                >
                  {coverDetails.cover2_maximumPayout}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ManualSummary;
