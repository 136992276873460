import React from "react";
import "../../styles.css";
import Datasets from "./Datasets";
import { useMap } from "../../hooks/useMap";
import { useDispatch, useSelector } from "react-redux";
import {
  changeshowModisNDVIDot,
  changeshowSentinelNDVIDot,
} from "../../redux/action";

const VisualDataset = (props) => {
  const dispatch = useDispatch();
  const { fishnetLayer, setFishnetLayer, map } = useMap();
  const stateName = useSelector((state) => state.stateName);
  const level3Data = useSelector((state) => state.level3Data);
  const districtName = useSelector((state) => state.districtName);
  const level4Data = useSelector((state) => state.level4Data);
  return (
    <>
      <div className="visualise-boundary">
        <div>
          <div className="exploretext">
            <img
              src="chevron-left.png"
              alt="location icon"
              style={{ marginRight: 10 }}
              onClick={() => {
                if (fishnetLayer !== null) {
                  setFishnetLayer(null);
                  map.removeLayer(
                    map
                      .getLayers()
                      .getArray()
                      .find((layer) => layer.get("name") === "highlightLayer")
                  );
                }
                props.setChirps5kmMonthlyRainfall(false);
                props.setTamsatDailyGraph(false);
                props.setTamsatMonthlyGraph(false);
                props.setarc210kmDailyRainfall(false);
                props.setTamsatMonthlyGraph(false);
                props.setModis5DayIntervalNDVIGraph(false);
                props.setlandcover(false);
                props.setChirps5kmDailyRainfall(false);
                props.setera5DailyRainfallGraph(false);
                props.setera5MonthlyRainfallGraph(false);
                props.setera5DailyTemperatureGraph(false);
                props.setera5MonthlyTemperatureGraph(false);
                props.setforecastedChirpsDailyRainfallGraph(false)
                props.setgsMapDaily(false);
                props.setgsMapMonthly(false);
                props.setsentinal2NDVI(false);
                dispatch(changeshowModisNDVIDot(false));
                dispatch(changeshowSentinelNDVIDot(false));
                props.setExploreDataset(false);
              }}
            />
            <div> Select Datasets</div>
          </div>
          <div className="exploresecondarytext">
            Select datasets to visualise them as charts or on the map.
          </div>
          <div className="selectedloctext">Showing datasets for</div>
          <button className="selectedloc-buttonvisual">
          <img src="loc-pin.png" alt="icon" style={{ marginRight: 5 }} />
          <span className="button-text">
            {props.countryName}, {stateName}, {districtName}, {level3Data}, {level4Data}
          </span>
        </button>

          <hr className="horizontal-rule" />
          <div className="datasetstext">Datasets</div>
          <Datasets
            countryName={props.countryName}
            tamsatDailyGraph={props.tamsatDailyGraph}
            setTamsatDailyGraph={props.setTamsatDailyGraph}
            tamsatMonthlyGraph={props.tamsatMonthlyGraph}
            setTamsatMonthlyGraph={props.setTamsatMonthlyGraph}
            chirps5kmMonthlyRainfall={props.chirps5kmMonthlyRainfall}
            setChirps5kmMonthlyRainfall={props.setChirps5kmMonthlyRainfall}
            arc210kmDailyRainfall={props.arc210kmDailyRainfall}
            setarc210kmDailyRainfall={props.setarc210kmDailyRainfall}
            modis5DayIntervalNDVIGraph={props.modis5DayIntervalNDVIGraph}
            setModis5DayIntervalNDVIGraph={props.setModis5DayIntervalNDVIGraph}
            setIsDrawerOpen={props.setIsDrawerOpen}
            landcover={props.landcover}
            setlandcover={props.setlandcover}
            chirps5kmDailyRainfall={props.chirps5kmDailyRainfall}
            setChirps5kmDailyRainfall={props.setChirps5kmDailyRainfall}
            era5DailyRainfallGraph={props.era5DailyRainfallGraph}
            setera5DailyRainfallGraph={props.setera5DailyRainfallGraph}
            era5MonthlyRainfallGraph={props.era5MonthlyRainfallGraph}
            setera5MonthlyRainfallGraph={props.setera5MonthlyRainfallGraph}
            era5DailyTemperatureGraph={props.era5DailyTemperatureGraph}
            setera5DailyTemperatureGraph={props.setera5DailyTemperatureGraph}
            era5MonthlyTemperatureGraph={props.era5MonthlyTemperatureGraph}
            setera5MonthlyTemperatureGraph={
              props.setera5MonthlyTemperatureGraph
            }
            gsMapDaily={props.gsMapDaily}
            setgsMapDaily={props.setgsMapDaily}
            gsMapMonthly={props.gsMapMonthly}
            setgsMapMonthly={props.setgsMapMonthly}
            sentinal2NDVI={props.sentinal2NDVI}
            setsentinal2NDVI={props.setsentinal2NDVI}
            forecastedChirpsDailyRainfallGraph={props.forecastedChirpsDailyRainfallGraph}
            setforecastedChirpsDailyRainfallGraph={props.setforecastedChirpsDailyRainfallGraph}
          />
        </div>
      </div>
    </>
  );
};

export default VisualDataset;
