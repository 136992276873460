import './App.css';
import React, { useState } from "react";
import { MainLayout } from './components/MainLayout';
import { Amplify} from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import { withAuthenticator } from "@aws-amplify/ui-react";
import awsmobile from "./aws-exports";
import Login from './components/Login/Login';
import { Auth, Analytics } from "aws-amplify";

Amplify.configure(awsmobile);



function App() {
const [mainscreen, setMainscreen] = useState(false);
const [email, setEmail] = useState('');
  return (
    <div className="App">
     {mainscreen?  <MainLayout
     email={email}
     setEmail={setEmail}/>
     :
     <Login 
     email={email}
     setEmail={setEmail}
     mainscreen={mainscreen}
     setMainscreen={setMainscreen}/>}
    </div>
  );
}

export default App;
//export default withAuthenticator(App);
